
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { PivotTableConfig } from '../../../../../app/models/response/widget.ro';
import { PivotTableData, mergeCellVertically, replaceNameNashi, selectType, sortArray } from '../../../../../app/_helper/helper';
import { DateFormat, DeviceType, FooterOption, FormatType, SummaryColumnOption } from '../../../../../app/enum/common-enum';
import { WidgetService } from '../../../../../app/services/modules/widget.service';
import { DetectDeviceService, IDeviceType } from 'src/app/services/detect-device.service';
import { JapanDateFormat, PivotFooterOptions } from '../../../../../app/const/const';
import * as moment from 'moment';
import { drop, head, isNumber } from 'lodash';
import { cloneDeep, fill, last, orderBy } from 'lodash';
import { ProcessLoadingService } from '../../../../../app/services/loading.service';
import { DialogService } from 'primeng/dynamicdialog';
import { DialogFilterTableComponent } from '../../../../../app/module/dialog-filter-table/dialog-filter-table.component';
import { ROUTE_PATH } from '../../../../../app/const/route-path';
import { COMMON_TEXT } from 'src/app/const/text-common';

@Component({
  selector: 'pivot-table-chart',
  templateUrl: './table-chart.component.html',
  styleUrls: ['./table-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TableChartComponent implements OnChanges, OnInit, AfterViewInit ,OnDestroy{

  @Input() data: PivotTableData
  @Input() sortParams: any = null;
  @Input() isFilterByOffice?: boolean = false;
  @Output() handleSortData = new EventEmitter();
  @ViewChild('table', { read: ElementRef, static: false }) table: ElementRef|undefined;
  @Output() handleClick = new EventEmitter();

  headers: Array<Array<{ value: string | number, colspan: number, rowspan: number, formattype: FormatType, datatype?: string, cssStyle?: string }>>
  body: Array<Array<{ value: string | number, rowspan: number, formattype: FormatType, cssStyle?: string }>>
  bodyRender: Array<Array<{ value: string | number, rowspan: number, formattype: FormatType, cssStyle?: string }>>
  bodySlice: Array<Array<{ value: string | number, rowspan: number, formattype: FormatType, datatype?: string, cssStyle?: string }>>
  footers: Array<{ value: string | number, colspan: number, cssStyle?: string }>
  bodyCurrent: Array<Array<{ value: string | number, rowspan: number, formattype: FormatType, cssStyle?: string }>>
  config: PivotTableConfig
  deviceType: string;
  DeviceType = DeviceType;
  pIndexs: any[] = [];
  footertype: string;
  summaryColumnType: SummaryColumnOption;
  emptyMessage: string = COMMON_TEXT.NO_DATA;
  colnLength: number = 0;
  bodyLength: number = 0;

  replaceNameNashi = replaceNameNashi
  pageSize: number = 1000;
  startIndex: number = 0;
  readonly rowHeight: number = 30;
  filterData: any[] = []
  filterSelecteds: any[] = []
  isFilter: boolean = false
  sortArr: string[] = [];
  isLoadDing: boolean = false;

  constructor(
    private widgetService: WidgetService,
    private detectDeviceService: DetectDeviceService,
    private processLoadingService: ProcessLoadingService,
    private cdr: ChangeDetectorRef,
    public modalService: DialogService
    ) {
    this.detectDeviceService.currentDevice.subscribe((device: IDeviceType) => {
      if (device) this.deviceType = device.type;
    });
  }
  ngOnDestroy(): void {
    this.headers = [];
    this.body = []
    this.bodyRender = [];
    this.bodySlice = [];
    this.footers = [];
    this.bodyCurrent = [];
    this.config = new PivotTableConfig();


    this.filterData = []
    this.filterSelecteds = []
    this.isFilter = false
    this.sortArr = [];
    this.table = undefined;
    
  }


  ngOnInit(): void {
    // subscribe for change footer type in widget setting
    this.colnLength = this.data?.table?.headers ? this.data?.table?.headers[0]?.length : 0;
    this.bodyLength = this.data?.table?.body?.length;
    this.widgetService.footertype$.subscribe((type: string | undefined) => {
      this.footertype = type || ""
      if (type && this.data?.table?.footers && this.bodyRender.length == this.bodySlice.length) {
        const footers = this.data.table.footers[type]
        this.footers = mergeCellFooter(footers, this.config)
      } else {
        this.footers = []
      }
    })

    this.widgetService.summaryColumntype$.subscribe((type: string | undefined) => {
      this.summaryColumnType = type as SummaryColumnOption;
      //If having footers need to recalculate the footers
      if(this.data?.table?.footers && this.bodyRender.length == this.bodySlice.length) {
        const footers = this.data.table.footers[this.footertype]
        this.footers = mergeCellFooter(footers, this.config)
      }
    })
    
    let pathname = window.location.pathname;
    if (
      pathname?.includes(ROUTE_PATH.DASHBOARD_DETAIL) ||
      pathname?.includes(ROUTE_PATH.HOME)) {
        this.isFilter = true
      } else {
        this.isFilter = false
    }
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.isLoadDing = false;
    if(this.sortParams) this.sortArr = this.sortParams?.sortArr || [];
    if (this.data.config) {
      if(this.filterSelecteds?.length > 0) return
      this.config = this.data.config
      this.footertype = this.config?.footers[0]?.formattype || ""
      this.summaryColumnType = this.config.summaryColumns[0]?.formattype as SummaryColumnOption
      this.pIndexs = this.config.values.map((x: any, i: number) => {
        return x.formattype === FormatType.Percent ? i : -1
      }).filter(x => x != -1)
  
      // clone the object and replace special character "\" => "¥"
      let headers = this.data?.table?.headers?.map(x =>{
        for (let key in x){
          if (typeof x[key] == 'object') {
            if (typeof x[key].value == 'string') {
              x[key].value = x[key].value.replace(/\\/g, "¥");
            }
          }
        }
        return [...x];
      });
      
      let body = this.data?.table?.body?.map(x =>{
        for (let key in x){
          if (typeof x[key] == 'object') {
            if (typeof x[key].value == 'string') {
              x[key].value = x[key].value.replace(/\\/g, "¥");
            }
          }
        }
        return [...x];
      });

      this.headers = mergeCellHeader(headers, this.summaryColumnType, this.config)
      this.body = mergeCellVertically(body, this.config)
      let isSortGrp = this.sortArr.length == 0 || this.sortArr?.filter(s=> s != 'none')?.length == 0 ? true : false;
      if(this.body?.length > 0 && isSortGrp ) {
        let index = this.body[0]?.findIndex(s=>s.formattype?.toString()?.includes(FormatType.Group));
        if(index != -1) {
          this.bodyRender = sortArray(this.body, 'none', (x: any) => x[index].value, index)
        }
      } 
      if(this.sortArr.length == 0) {
        this.sortArr = fill(Array(last(this.headers)?.length), 'none')
      } else {
        let countHeader = last(this.headers)?.length || 0;
        if(this.sortArr?.length != countHeader) {
          if(this.sortArr?.length > countHeader)  this.sortArr = this.sortArr.splice(0, countHeader);  
          else {
            let countDifference = countHeader - this.sortArr?.length || 0;
            for(let i = 0; i< countDifference; i++) {
              this.sortArr.push('none');
            }  
          }
          this.handleSortData.emit({ sortArr: this.sortArr, headers: last(this.headers) });
        }
        this.bodyRender = cloneDeep(this.body)
        this.setBodySlice();
       
        let dataWorker = {
          typeWorker: "sortPivotTable",
          sortArr: this.sortArr,
          config: this.config,
          bodyRender: this.bodyRender
        }
        if (typeof Worker !== 'undefined') {
          this.isLoadDing = true;
          // Create a new Web Worker instance
          const worker = new Worker(new URL('../../../../workers/pivot-table-sort.worker.ts', import.meta.url), { name: 'pivot_table_sort_worker', type: 'module' });
          // Send the bulk data to the Web Worker
          worker.postMessage(dataWorker);
    
          // Listen for messages from the Web Worker
          worker.onmessage = ({ data }) => {
            if(data) {
              // Handle the result from the Web Worker here
              this.bodyRender = data
              this.setBodySlice();
            }
            worker.terminate()
            this.isLoadDing = false;
          };
          
        } else {
          this.processLoadingService.isLoading.emit(false);
        }
      }
      this.bodyCurrent = cloneDeep(this.body)
      this.bodyRender = cloneDeep(this.body)
      this.setBodySlice();
      if (this.footertype && this.data?.table?.footers && this.bodyRender.length == this.bodySlice.length) {
        const footers = this.data.table.footers[this.footertype]
        this.footers = mergeCellFooter(footers, this.config)
      }
    }
  }

  ngAfterViewInit() {
    if(this.table?.nativeElement.offsetHeight >= this.pageSize * this.rowHeight){
      this.bodySlice = cloneDeep(this.bodyRender) ;
      if (this.footertype && this.data?.table?.footers) {
        const footers = this.data.table.footers[this.footertype]
        this.footers = mergeCellFooter(footers, this.config)
      }
      this.cdr.detectChanges();
    }else{
      this.table?.nativeElement?.addEventListener('scroll', this.onTableScroll.bind(this));
    }
  }
  onTableScroll() {
    // const rowHeight = this.table.nativeElement.querySelector('tbody tr').offsetHeight;
    const scrollTop = this.table?.nativeElement.scrollTop;
    const visibleStart = Math.floor(scrollTop / this.rowHeight);
    const visibleEnd = visibleStart + Math.ceil(this.table?.nativeElement.offsetHeight / this.rowHeight);
    if(visibleEnd > (this.startIndex + this.pageSize)) {
      // const newData = this.bodyRender.slice(this.startIndex + this.pageSize, this.startIndex + this.pageSize * 2);
      // this.bodySlice = [...this.bodySlice, ...newData];
      this.bodySlice = this.bodyRender.slice(0, this.startIndex + this.pageSize * 2);
      if(this.data?.table?.footers && this.bodyRender.length == this.bodySlice.length) {
        const footers = this.data.table.footers[this.footertype]
        this.footers = mergeCellFooter(footers, this.config)
      }
      this.startIndex += this.pageSize;
    }
  }

  beautify(value: any, formattype?: string, i?: number, datatype?: string, isHeaderValue?: boolean) {
    if(typeof value === 'object' && value != null && typeof value.value === 'undefined' )  return COMMON_TEXT.VALUE_EMPTY;
    if((typeof value === 'number' && isNaN(value)) || value === "NaN" || value == Infinity || value == -Infinity) return COMMON_TEXT.VALUE_EMPTY;  
    //stupid javascript
    if(value === "0.00") return value;
    //in file helper.ts line  roundDown() functiion sometimes return value -0.
    if(value === -0) value = 0;
    const { rows, columns, values } = this.config
    const { Sum, Average } = SummaryColumnOption
    if (isNumber(i) && i >= rows.length && this.pIndexs.length > 0) {
      const isPercent = this.pIndexs.includes((i - rows.length) % values.length) && !isNaN(value);
      const haveSummaryColumn = this.summaryColumnType !== null && this.summaryColumnType !== undefined;
      const isSumOrAvg = [Sum, Average].includes(this.summaryColumnType);
      const isBeforeSummaryColumn = i < Number(last(this.headers)?.length) - values.length;
    
      if (isPercent && !haveSummaryColumn) {
        return Number(value).toFixed(2) + '%';
      } else if (isPercent && haveSummaryColumn) {
        if (isBeforeSummaryColumn || (!isBeforeSummaryColumn && isSumOrAvg)) return  this.formatDataType(Number(value).toFixed(2), 'FLOAT') +  '%';
      }
    }
    const allItems = [...rows, ...columns];
    const haveGroup = allItems.some(item => item.formattype?.includes(FormatType.Group));
    if(haveGroup && value == undefined && isNumber(i) && i >= rows.length) {
      value = 0;
    }

    if (!formattype) {
      if(datatype != null && datatype != "") {
        return this.formatDataType(value, datatype);
      }
      else {
        //isHeaderValue = true is apply beautify for headers of table.
        if(!isHeaderValue) {
          // The purpose of this method to format string '1,000.21'
          if(typeof value == 'string') {
            let stringNumber: string = value;
            let containDot = stringNumber.includes(".");
            if(containDot == true) {
              let numberArray = stringNumber.split(".");
              if( numberArray.length == 2 && (!Number.isNaN(Number(numberArray[0].replace(",", "")))) ||  !Number.isNaN(Number(numberArray[1]))) {
                let containComma = numberArray[0].includes(",");
                return containComma ? value : Number(stringNumber).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })
              }
              return value;
            }
            else return value;
          }
          else 
          {
            let lastHeaders = last(this.headers) || [];
            if(i && i < lastHeaders.length) { 
              let col = lastHeaders[i];
              let formatType = col? values.find(v => v.displayname == col.value)?.formattype || undefined : undefined;
              const isAvg = formatType == FormatType.Average ? true: false;
              if(!isAvg) return isNumber(value) ? this.formatDataType(value, "FLOAT") : value;
              else return isNumber(value) ?  Number(value).toLocaleString(undefined, {
               minimumFractionDigits: 2,
               maximumFractionDigits: 2
             }): value
            }
            else return isNumber(value) ? this.formatDataType(value, "FLOAT") : value;
          }
        }
        else  return isNumber(value) ? this.formatDataType(value, "FLOAT") : value;
      }
    }

    // string is not empty and not just whitespace
    if (!/\S/.test(String(value))) return value
    
    const type = selectType(formattype, 'A')
    if (!JapanDateFormat[type!]) return value
  
    return moment(value,'YYYY-MM-DD').format(JapanDateFormat[type] as string);

  }

  formatDataType(value: any, datatype: any) {
    if(value == null ) return "";

    switch (datatype.toUpperCase()) {
      case "VARCHAR":
        return value;
      case "INT":
      case "FLOAT":
        return value.toLocaleString();
      case "DATETIME":
        value = value.trim();
        if(value == "") return "";
        return moment(value).format(DateFormat.FULL_SHORT_DASH_DATE_TIME);
      default:
        return value;
    }
  }

  formatFooterValue(value: any, index: number): any {
    const { rows, values } = this.config
    const { Count, CountUnique,Average, Sum } = FooterOption
    const footerType = this.footertype as FooterOption
    const haveSummaryColumn = this.summaryColumnType !== null && this.summaryColumnType != undefined;
    const isSumOrAvg = [Sum, Average].includes(footerType);
    const numberOfNormalColumns = Number(last(this.headers)?.length) - rows.length - (haveSummaryColumn ? values.length : 0);
    const isBeforeSummaryColumn = index - rows.length < numberOfNormalColumns;
    
    if (index < rows.length || footerType === Count || footerType === CountUnique) return value
    
    const valueIndex = (index - rows.length) % values.length
    const isPercentColumn = this.pIndexs.includes(valueIndex)

    if(isNaN(value) || value == Infinity || value == -Infinity) return COMMON_TEXT.VALUE_EMPTY;
    if (!isPercentColumn || !isSumOrAvg) {
      if(footerType == FooterOption.Average) {
        let number = Number.parseFloat(value).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        return number;
      }
      else {
        return this.formatDataType(value, "FLOAT")
      }
    }

    if (isBeforeSummaryColumn) {
      if (footerType === Sum) {
        if ( value >= 99.9){
          return '100%'
        }else if (value == 0){
          return '0%'
        }else {
          return Number(value).toFixed(2) + '%'
        }
      }
      if (footerType === Average) return Number(value).toFixed(2) + '%'
    } else {
      if (footerType === Sum) {
        if (this.summaryColumnType === SummaryColumnOption.Sum) {
          if(values.filter((v: any) => v.pivotfiltertype)?.length > 0) {
            return value >= 99.9 ? '100%': Number(value).toFixed(2) + '%'
          } else if (value == 0){
            return '0%'
          }
          else return  Number(value).toFixed(2) + '%'
        }
        if (this.summaryColumnType === SummaryColumnOption.Average) return Number(Number(value).toFixed(2)) + '%'
      }
      if (footerType === Average) {
        if ([SummaryColumnOption.Sum, SummaryColumnOption.Average].includes(this.summaryColumnType)) return Number(value).toFixed(2) + '%'
      }
    }
    return isNumber(value) ? this.formatDataType(value, "FLOAT"): value ;
  }
  scrollToTop() {
    this.startIndex = 0;
    // this.table?.nativeElement.scrollTop = 0; // scroll to the top of the table
    if (this.table) {
      let elementScroll = this.table?.nativeElement;
      if (elementScroll) {
        elementScroll.scrollTop = 0;
      }
    }
  }

  onSortCol(colIndex: number) {
    this.processLoadingService.isLoading.emit(true);
    if (this.sortArr[colIndex] == 'none') {
      this.sortArr[colIndex] = 'asc'
    } else if (this.sortArr[colIndex] == 'asc') {
      this.sortArr[colIndex] = 'desc'
    } else {
      this.sortArr[colIndex] = 'none'
    }
    this.scrollToTop()
    let countHeader = last(this.headers)?.length || 0;
    while (this.sortArr?.length < countHeader) {
      this.sortArr.push('none');
    }
    if (this.sortArr.findIndex(x => x !== 'none') === -1) {
      this.bodyRender = cloneDeep(this.body)
      this.setBodySlice();
      this.processLoadingService.isLoading.emit(false);
      this.handleSortData.emit({ sortArr: this.sortArr, headers: last(this.headers) });
      this.handleClick.emit();
      return;
    }
    let dataWorker = {
      typeWorker: "sortPivotTable",
      sortArr: this.sortArr,
      config: this.config,
      bodyRender: this.bodyRender
    }
    if (typeof Worker !== 'undefined') {
      
      // Create a new Web Worker instance
      const worker = new Worker(new URL('../../../../workers/pivot-table-sort.worker.ts', import.meta.url), { name: 'pivot_table_sort_worker', type: 'module' });
     
      // Send the bulk data to the Web Worker
      worker.postMessage(dataWorker);

      // Listen for messages from the Web Worker
      worker.onmessage = ({ data }) => {
        if(data) {
          // Handle the result from the Web Worker here
          this.bodyRender = data
          this.setBodySlice();
          
        }
        worker.terminate()
        this.processLoadingService.isLoading.emit(false);
      };
      
    } else {
      this.processLoadingService.isLoading.emit(false);
    }
    this.handleSortData.emit({ sortArr: this.sortArr, headers: last(this.headers) });
    this.handleClick.emit();
  }

  filterPivotTableWorker(data: any) : Promise<any[]> {
    // Create a new Web Worker instance
    const worker = new Worker(new URL('../../../../workers/pivot-table-filter.worker.ts', import.meta.url), { name: 'pivot_table_filter_worker', type: 'module' });     
   return new Promise((resolve, reject) => {
     worker.onmessage = ({ data }) => {
       resolve(data);
       this.processLoadingService.isLoading.emit(false);
       worker.terminate()
     };
     worker.onerror = (error) => {
       reject(error);
     };
     worker.postMessage(data);
   });
 }

  showDialogFilter(index: any) {
    let bodyFilter: any[] = [];
    if(this.filterSelecteds.length > 0) {
      let dataSelected = cloneDeep(this.filterSelecteds)
      dataSelected.map(ft => {
        if(ft.index == index) 
          ft.value = []
      })
      let dataWorker = {
        typeWorker: "filterPivotTable",
        filterArr:  cloneDeep(dataSelected),
        config: this.config,
        body: this.bodyCurrent
      }
   
      if (typeof Worker !== 'undefined') { 
        this.filterPivotTableWorker(dataWorker).then(data => {
          if(data) {
            bodyFilter = data;
            let lstData: any = this.filterSelecteds.length > 0 ?  cloneDeep(bodyFilter) :  cloneDeep(this.bodyCurrent) 
            this.callDialogFilter(index, lstData)
          }
        })
      }
    }
    else {
      this.callDialogFilter(index, this.bodyCurrent)
    }
  }

  callDialogFilter(index: any, lstData: any[]) {
    this.getListFilterData(lstData, index);
    this.filterData = orderBy(this.filterData || [], ["value"])
    let ref = this.modalService.open(DialogFilterTableComponent, {
      data: {
        filterData: this.filterData,
        rowIndex: index,
        colnm: this.config.rows[index]?.displayname
      },
      header: COMMON_TEXT.SORTTABLE,
      width: '400px'
    });

    ref.onClose.subscribe((x: any) => {
      if(!x) return
      this.setListFilterData(x)
      let dataWorker = {
        typeWorker: "filterPivotTable",
        filterArr:  cloneDeep(this.filterSelecteds),
        config: this.config,
        body: this.bodyCurrent
      }
      if (typeof Worker !== 'undefined') {
        this.filterPivotTableWorker(dataWorker).then(data => {
          if(data) {
            this.body = data
            let footertype = this.config.footers[0]?.formattype
            if(footertype && this.data?.table?.footers && this.bodyRender.length == this.bodySlice.length) {
              const footers = this.data.table.footers[footertype]
              this.footers = this.calculatorCellFooter(footers, this.config, footertype)
            } 
            this.scrollToTop()
            this.bodyRender = cloneDeep(this.body)
            this.setBodySlice();
            this.processLoadingService.isLoading.emit(false)
          }
        })
        
      } else {
        this.processLoadingService.isLoading.emit(false);
      }
    })
  }

  checkDataExist(index: number) {
    let exist = false
    this.filterSelecteds?.forEach(ft => {
        if(index == ft.index) 
          exist = true;
    })
    return exist
  }

  setListFilterData(filter: any) {
    if(!filter) return;
    for(let i = 0; i <= filter[0]?.index; i++) {
      let data = filter[0]?.index == i? filter[0] : []
      let exist = this.checkDataExist(filter[0]?.index)
      if(exist) {
        this.filterSelecteds[filter[0]?.index].value = filter[0]
      }
      else {
        let existed = this.checkDataExist(i)
        if(!existed) {
          this.filterSelecteds.push({ value: data, index: i == filter[0].index? filter[0].index: i })
        }
      }
    }
  }

  getListFilterData(data: any[], index: number) {
    this.filterData = []
    let row = this.config.rows.length || 0;
    data.forEach((s:any)=> {
      let rowData = [];
      for(let i = 0; i< row; i++) {
        rowData.push({ 
          colnm: this.config.rows[i].columnname, 
          value: s[i].value, 
          checked: (this.filterSelecteds[index]?.value?.data && this.filterSelecteds[index]?.value?.data?.findIndex((ft: any) => ft.value == s[i].value) != -1) ?  true: false,
          datatype: s[i].datatype,
          formattype :  s[i].formattype
        } )
      }
      this.filterData.push(rowData);
    })
  }

  calculatorCellFooter(footer: any[], config: PivotTableConfig, formattype: string) {
    let countColumn = this.body[0]?.length || 0
    let tableData: any[] = [];
    for(let i = 0; i< countColumn;  i++) {
      let values = this.body.map(x => x[i].value)
        let sum: number = Number(values.reduce((a, b) => Number(a || 0) + Number(b || 0), 0))
        let count: number = values.filter(x => x != undefined).length
          switch (formattype) {
            case FooterOption.Sum:
              tableData.push(sum);
              break;
            case FooterOption.Average:
              tableData.push((sum / (count || 1)).toFixed(2))
              break;
            case FooterOption.CountUnique:
              tableData.push((new Set(values).size))
              break;
            case FooterOption.Count:
            default:
              tableData.push(count);
              break;
        }
    }
    const { rows } = config
    return footer.map((x, i) => {
      let obj = {
        colspan: 1,
        value: i < rows.length  ? x : tableData[i],
      }

      if (i < config.rows.length) {
        if(i == 0)
          obj.colspan = rows.length
        else obj.colspan = 0
      }
      return obj
    })
  }
  
  getFooterName(footertype: string ) {return PivotFooterOptions.find(x => x.value == footertype)?.name || ""}
  
  setBodySlice(){
    this.bodySlice = this.bodyRender.slice(0,this.pageSize)
    if(this.bodySlice.length != this.bodyRender.length){
      this.footers = [];
    }
  }
}

/**
 * merge cell
 * | A | A |  =>  |   A   |
 * | B | C |      | B | C |
 * 
 */
export const mergeCellHeader = (table: any[][], summaryColumnType: SummaryColumnOption, config: PivotTableConfig) => {
  let previousRow: any[] = []

  let headers = table?.map((x, z) => {
    const spans: number[] = []
    const length = x.length || 0
    const lastIndex = length - 1

    let previousValue = x[lastIndex]?.value
    let spanProcessor = 1

    if (length > 1) {

      spans[lastIndex] = x[lastIndex]?.value == x[length - 2]?.value
        && (!previousRow.length || previousRow[lastIndex]?.colspan == 0) ? 0 : 1

      // iterate table, add colspan to the cell
      // then transform value  =>  { value, colspan }
      for (let i = length - 2; i >= 0; i--) {

        if (x[i].value == previousValue && (!previousRow.length || previousRow[i + 1].colspan == 0)) {

          spanProcessor++

        } else {

          spans[i + 1] = spanProcessor
          spanProcessor = 1
        }

        previousValue = x[i].value
        spans[i] = 0
      }

      spans[0] = spanProcessor

    } else {

      spans[0] = 1
    }

    if (z == table.length - 1) x.map((r, index) => (spans[index] = 1));

    const currentRow = x?.map((y, i) => ({
      colspan: getColSpan(summaryColumnType ,spans[i], table, z, i, config),
      rowspan: getRowSpan(summaryColumnType, table, z, i, config),
      value: typeof y === 'object' ? y.value : y,
      formattype: y.formattype
    }))

    previousRow = currentRow

    return currentRow
  })
  if(!summaryColumnType && !config.columns.length) return drop(headers)
  return headers
}

const getRowSpan = (summaryColumnType: SummaryColumnOption, headers: any[][], rowHeaderIndex: number, colHeaderIndex: number, config: PivotTableConfig) => {
  if (!summaryColumnType) return 1
  const { columns, values } = config
  const fistSummaryColumn = Number(last(headers)?.length) - values.length
  const isAfterSummaryColumn = colHeaderIndex >= fistSummaryColumn;
  if (!isAfterSummaryColumn || rowHeaderIndex == columns.length) return 1
  if (rowHeaderIndex == 0 && colHeaderIndex == fistSummaryColumn) {
    return columns.length
  } else {
    return 0
  }
}

const getColSpan = (summaryColumnType: SummaryColumnOption, span: number, headers: any[][], rowHeaderIndex: number, colHeaderIndex: number, config: PivotTableConfig) => {
  if (!summaryColumnType) return span
  const { columns, values } = config
  const fistSummaryColumn = Number(last(headers)?.length) - values.length
  if (rowHeaderIndex != 0 && rowHeaderIndex != (columns.length || 1) && colHeaderIndex == fistSummaryColumn) return 0
  return span
}

const mergeCellFooter = (table: any[], config: PivotTableConfig) => {
  return table?.map((x, i) => {
    let obj = {
      colspan: 1,
      value: x,
    }

    if (i === 0) {
      obj.colspan = config.rows?.length || 1
    } else if (i < config.rows?.length) {
      obj.colspan = 0
    }

    return obj
  })
}


