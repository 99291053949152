import { GraphType, WidgetSelectDataType } from "../../../app/enum/common-enum";
import { DATATYPE, DSTARGET } from "../../../app/const/const";
import { ActivityLog } from "../api-model";
import { columntype } from "../common-model";
import { CHART_TYPE, PivotTableConfig, WidgetColumn, Widget as WidgetRo } from "../response/widget.ro";
import { ConditionDTO } from "./condition.dto";
import {convertFilterValueToArray, reFormatFilterValueForTotaldays} from "src/app/_helper/helper";

export class WidgetGetDTO {
    id?: String;
    ids?: Array<string>;
    folderId?: string;
    folderCd?: string;
    dashboardId?: string;
    condition?: ConditionDTO;
}

export class WidgetAddDTO extends ActivityLog {
    widgetCd?: string;
    name: string; // ウィジェット名
    description?: string; // 説明
    folderId: string; // フォルダ名
    folderCd?: string; // フォルダ名
    folderName?: string;
    chartType: CHART_TYPE; // 表示形式
    dataTableConfig: Array<WidgetColumn>; // 絞込み
    pivotTableConfig: PivotTableConfig;
    datasources?: Array<string>; // Array of datasourceId
    delflg?: boolean;
}


export class WidgetUpdateDTO extends WidgetRo {

}

export class WidgetDeleteDTO extends WidgetGetDTO {

}

export class Widget extends ActivityLog {
    id?: string
    widgetcd?: string
    foldercd: string
    widgetname: string
    widgetdesc: string
    charttype: GraphType
    delflg: boolean = false
    dsstructcd?: string;
    sortcoltype?: string;
}

export class WidgetDetail extends ActivityLog {
    id?: string
    widgetdetailcd?: string
    widgetdesc?: string
    widgetcd?: string
    widgetname?: string
    datasourcecd: string
    columnname: string
    columnid: string
    sortno: number
    operator?: string | any
    rounding?: string
    columntype: columntype
    filtertype?: string
    filtervalue?: any
    pivotfiltertype?:string
    pivotfiltervalue?: string
    displayname: string
    footertype?: string
    datatype?: string
    formattype?: string
    pivotorder: number | null
    utcsortno: number
    delflg: boolean = false
    utcdelflg? = false
    charttype? : number
    sortcoltype?: string
    groupfilterval?: string 
    isnotuse?: boolean
    range?: string
    dsdelflg? : boolean
    isHaveTargetCol?: boolean

    constructor(detail?: any) {
        super()
        if (detail) {
            this.id = detail.wdid
            this.widgetdetailcd = detail.widgetdetailcd
            this.datasourcecd = detail.datasourcecd
            this.widgetcd = detail.widgetcd
            this.widgetname = detail.widgetname
            this.columnid = detail.columnid
            this.columnname = detail.columnname
            this.columntype = detail.columntype || 0
            this.displayname = detail.columnname === "TOTALDAYS" ? "利用日数" : detail.displayname || detail.columnname
            this.sortno = detail.sortno
            this.delflg = detail.delflg || false
            this.footertype = detail.footertype
            this.operator = detail.operator
            this.rounding = detail.rounding
            this.filtertype = detail.filtertype
            this.filtervalue = detail.filtervalue;
            this.filtervalue = detail.filtervalue
            this.pivotfiltertype = detail.pivotfiltertype
            this.pivotfiltervalue = detail.pivotfiltervalue
            this.pivotorder = detail.pivotorder
            this.utcsortno = detail.utcsortno
            this.insdate = detail.wdinsdate
            this.insstfcd = detail.wdinsstfcd
            this.formattype = detail.formattype
            this.utcdelflg = detail.utcdelflg
            this.charttype = detail.charttype
            this.sortcoltype = detail.sortcoltype
            this.groupfilterval = detail.groupfilterval
            this.dsdelflg = detail.dsdelflg
            this.isHaveTargetCol = detail.isHaveTargetCol

            if (['EDATE', 'SDATE'].includes(this.columnname)) {
                this.datatype = DATATYPE.Date
            } else {
                this.datatype = detail.datatype
            }

            if(this.datasourcecd === DSTARGET) {
                this.datatype = DATATYPE.Number
            }
            if(detail.filtertype && detail.filtertype.toUpperCase() === "TOTALDAYS") {
                this.filtervalue = reFormatFilterValueForTotaldays(convertFilterValueToArray(detail.filtervalue));
            }
            else {
                this.filtervalue = detail.filtervalue;
            }
            if(!this.datatype && this.columnname.toUpperCase() === "TOTALDAYS") this.datatype = "TOTALDAYSTYPE";
        }
    }
}

export class WidgetRequest {
    widget: Widget
    publicdate?: string
    widgetdetails: WidgetDetail[]
    widgetconfig?: any
}

export class WidgetSettingRecord {
    id: string
    targetColumnName: string
    targetColumn: any
    isColSetting: boolean
    isApplyAll: boolean
    column: SettingProperty
    row: SettingProperty
    targetTable: any[]
    lessThanColor: string = "#C8424C"
    greaterThanColor: string = "#0073BA"
    isNoColor: boolean = false;
}

export class SettingProperty {
    code : string
    name : string
    data?: WidgetDetail
    formatType?: any
}

export class SettingTargetConfig {
    id?: string = "";
    targetTable?: WidgetSettingRecord[] = []
}

export class WidgetResultRequest {
    widgetCds: string[]
    isTemplate: boolean
    selectType: WidgetSelectDataType
    filterCondition: FilterConditionRequest
}
export class FilterConditionRequest {
    isFullData: boolean
    officesSelected: string[]
}
