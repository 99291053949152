import { DSTARGET, FilterValueOption, InColumn, InRow, InValue, InHiddenValue, FilterGroupOptions, ColFilterGroup, FilterDateCurrent, MstFilterGroup, MstFilterIntItem } from './../../const/const';
import { DataSourceService } from './../../services/modules/data-source.service';
import { ChartType, ColumnType, DataType, DateFormat, FilterGroup, FormatType, LegendType, SaveType, WidgetSelectDataType } from './../../enum/common-enum';
import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { cloneDeep, groupBy, orderBy} from 'lodash';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmDialogComponent } from '../../../app/component/common/confirm-dialog/confirm-dialog.component';
import { DSCUSTOM, DATATYPE, InvisibleColumn, LBParam, MstFilterType, NoFilter, PivotOptions, PivotValueOptions, TVPParams, PivotFooterOptions, FooterName, PivotSummaryColumnOptions, SummaryColumnName } from '../../../app/const/const';
import { ROUTE_PATH } from '../../../app/const/route-path';
import { BUTTON, COMMON_TEXT, WIDGET_SETTING, WIDGET_TEMPLATE_SETTING } from '../../../app/const/text-common';
import { ButtonIconType, ButtonType, DialogType, GraphType, InputType, ScreenMode } from '../../../app/enum/common-enum';
import { bycd, ChartData, columntype, InputParams, item, ListBoxParams, ListItemParams, ModalTemplate, MstFilter, option, TreeNode, TreeViewInputParams } from '../../../app/models/common-model';
import { SettingTargetConfig, Widget, WidgetDetail, WidgetRequest, WidgetSettingRecord } from '../../../app/models/request/widget.dto';
import { PivotTableConfig, widget } from '../../../app/models/response/widget.ro';
import { TableData } from '../../../app/models/table-model';
import { ProcessLoadingService } from '../../../app/services/loading.service';
import { OfficeAPIService } from '../../../app/services/modules/office.service';
import { DashboardService } from '../../../app/services/modules/dashboard.service';
import { WidgetService } from '../../../app/services/modules/widget.service';
import Utils from '../../../app/util/utils';
import { delay, distinctBy, filtering, filteringMaxMin, filterByFinancialYearPeriod, makePivotConfig, PivotTableData, pushColumnToTable, replaceNameNashi, selectType, formulaToOperator, 
       findMinMaxValues, getValueFromFormatDate, isDecimal, getRoundNum, roundNumDecimal, evaluateFormula, minAndMaxArray, getDayOfWeekOrMonthCurrent, getRange, getRangeValueTypeDate, getRangesValueNumber, checkACustomColumnContainTargetColumns} from '../../../app/_helper/helper';
import { LocalStorageHelper } from '../../../app/_helper/local-storage.helper';
import { HeaderItem } from '../../models/table-model';
import { MstCommonService } from '../../services/modules/mstcommon.service';
import { v4 } from 'uuid';
import { MESSAGE_TEXT } from '../../../app/const/message';
import { ConfirmUnsavedDataDialogComponent } from '../../../app/component/common/confirm-unsaved-data-dialog/confirm-unsaved-data-dialog.component';
import { map, Observable, Subject, Subscription, take } from 'rxjs';
import * as moment from 'moment';
import { Title } from '@angular/platform-browser';
import { WindowService } from 'src/app/services/window.service';
import {AuthenticationService} from '../../services/authentication.service';
import { WidgetResultService } from 'src/app/services/modules/widget-result.service';
import {FolderService} from 'src/app/services/modules/folder.service';
import {FOLDER_TYPE, Folder} from 'src/app/models/response/folder.ro';
import {ErrorHandleService} from 'src/app/services/error-handle.service';
import {FUNCTION_TITLE_TEXT} from 'src/app/const/error-text';
import { CorpMstService } from 'src/app/services/modules/corpmst.service';
import TreeUtils from '../../util/treeUtils';

@Component({
  selector: 'pivot-widget-setting',
  templateUrl: './widget-setting.component.html',
  styleUrls: ['./widget-setting.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WidgetSettingComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("treeDropArea") treeDropArea: ElementRef = new ElementRef(null);
  @ViewChild("rowsListItem") rowsListItem: ElementRef;
  @ViewChild("colsListItem") colsListItem: ElementRef;
  @ViewChild("valueListItem") valueListItem: ElementRef;
  @ViewChild("valueFooterItem") valueFooterItem: ElementRef;
  @ViewChild("valuesummaryColumnItem") valuesummaryColumnItem: ElementRef;
  @ViewChild('lstOption', { static: false }) listOp: any;
  buttonType = ButtonType;
  iconType = ButtonIconType;
  dialogType = DialogType;
  modalData: ModalTemplate = new ModalTemplate();
  definedColModalData: ModalTemplate = new ModalTemplate();
  modalDataDelete: ModalTemplate = new ModalTemplate();
  modalCondition: ModalTemplate = new ModalTemplate();
  modalFilterGrp: ModalTemplate = new ModalTemplate();
  modalSettingTarget: ModalTemplate = new ModalTemplate();
  isDisplayDefineColumnDialog: boolean = false;
  isDisplayDatasourceSelectionDialog: boolean = false;
  conditionModalType: string;
  conditionModalColumnName: string;
  conditionFilterType: string;
  conditionFilterValue: string;
  displayConditionModal: boolean = false;
  dspFilterGrpModal: boolean = false;
  isDeleteWG: boolean = false;
  nameWidgetDelete: string[] = [] ;
  dashboardNames: any[];
  mode: ScreenMode = ScreenMode.ADD;
  isFilterPivotTable: boolean = false;
  chartWidth: number | undefined;
  chartHeight: number | undefined;
  isDisplayConfirmDeleteModal: boolean = false;
  delTxt : string;
  dataSourceIsDeleted: any[] = [];
  isStackedChartSetting: boolean = false;
  nameParams: InputParams = {
    inputStyle: { 'width': '300px' },
    placeholder: WIDGET_SETTING.WIDGET_NAME,
    type: InputType.text,
    pencil: true,
    validate: false,
    disabled: false,
    readonly: false,
    validateError: MESSAGE_TEXT.REQUIRE_INPUT_TEXT,
    borderFill: false,
  };
  selectedCustom: WidgetDetail | null
  columnF: WidgetDetail
  filterF: { filtertype: string, filtervalue: any }
  pivotFilterF:  { filtertype: string, filtervalue: any }
  filterParams: WidgetDetail[] = []
  pivotFilterParams: WidgetDetail[] = []
  saveType = SaveType;

  dataSource: TableData = {
    config: {
      id: 'data-source-table',
      caption: '',
      emptyMessage: COMMON_TEXT.NO_DATA,
      showIndex: false,
      showCheckbox: false,
      hoverShowCheckbox: false,
      showPagination: false,
      isResponsive: false
    },
    header: [],
    body: []
  };
  selected: ListBoxParams = {     // 絞込み
    ...cloneDeep(LBParam),
    onfilter: true,
    displayfilter: true,
    cssStyle: { width: '100%', height: '100%' },
  };
  sortParam: any = null;
  cols: ListItemParams
  rows: ListItemParams
  value: ListItemParams
  footers: ListItemParams
  hiddens: ListItemParams
  summaryColumns: ListItemParams
  folderTree: TreeViewInputParams = {
    ...cloneDeep(TVPParams),
    checkbox: false,
    allowParentSelect: true,
    selectable: true,
    hoverable: true
  };
  choosenDSTree: TreeViewInputParams = {
    ...cloneDeep(TVPParams),
    dragScope: 'pivot-config',
    isMultiDragItem: true
  };
  specialNode: TreeNode[] = []    // For datasource that used in the widget
  customNode: TreeNode | null
  dataType: string = '';
  loadingBag: any = {}
  filterOptions: item[] = []
  footerItem: WidgetDetail
  summaryColumnItem: WidgetDetail
  pageTitle: string
  footerType: any
  summaryColumnType: any
  isFilterGroup: boolean = false;
  showValidateErrDS: boolean = false;

  //#region Logic Variable

  sourceTable: any[] = []
  filteredTable: any[] = []
  formatedTable: any = []
  pivotTable: any[] = []
  chartData: ChartData | PivotTableData | undefined
  tableData: PivotTableData | undefined
  datasourceCDs: string[] = []
  widget: Widget = new Widget()
  widgetdetails: WidgetDetail[] = []
  usingWidgetDetails: WidgetDetail[] = []
  detailsBag: { [detailcd: string]: WidgetDetail } = {}
  seek: { [x: string]: columntype } = {
    rows: ColumnType.Row,
    cols: ColumnType.Column,
    value: ColumnType.Value,
  }
  holder: any = {}
  waiter$ = new EventEmitter();
  useCanDeactivate: boolean = true;
  isWidgetTemplate: boolean;
  isDisplayRangeDateModal: boolean = false;
  selectedRangeDate: any = {};
  itemSelected: any = null;
  pivotConfigSelection = [];
  rowsListItemSelection = [];
  footerSelection = [];
  summaryColumnSelection = [];
  colsListItemSelection = [];
  valueListItemSelection = [];
  isCreatingWidgetByTemplate: boolean = false;
  isUsingDSTemplate: boolean = false;
  isCreateWidgetByDSTemplate: boolean = false;
  datasourceCdTemplate: string = "";
  newSelectedDatasourceCd: string = "";
  useExistDatasource: boolean = false;

  officeList: any = [];
  startDate: any = new Date();
  endDate: any = new Date();
  isDisplayEdate: boolean = false;
  totodaysNode: TreeNode;
  isShowSettingTarget: boolean = false;
  settingValueConfig: any = undefined;
  graphConfig: any = undefined;
  hasWidgetSetting: boolean = false;
  colIndex: number = 0;
  rowIndex: number = 0;
  colFilterStr : string = ColFilterGroup + '_';
  filterArr: any[] = FilterValueOption.map(s => s.value);
  filterDateCurrent: any[] = FilterDateCurrent.map(c => c.value);
  targetSetting: WidgetSettingRecord[]= [];
  //set panel size
  panelLeftSize: any[] = [15.5,84.5];
  panelRightSize: any[] = [18,82];
  defaultLeftSize: any[] = [15.5,84.5];
  defaultRightSize: any[] = [18,82];
  _graphType = GraphType;
  isShowGraphSettings:boolean = false;
  isSavedGraphConfig: boolean = false;
  //#endregion

  ColumnType = ColumnType
  isShowOption: boolean = true;
  selectOption: any = null;
  moneyMonth: number = 1;

  BUTTON = BUTTON;
  COMMON_TEXT = COMMON_TEXT;
  WIDGET_SETTING = WIDGET_SETTING;
  MESSAGE_TEXT = MESSAGE_TEXT;
  nativeWindow: any = {};
  isSupporterAdmin: boolean;
  showGraphSetting: Subject<void> = new Subject<void>();
  // pivotLibRequest: any = {};
  isByPassConfirmDialog: boolean = true;

  apiServiceSubscriber: Subscription | undefined;

  screenMode = ScreenMode;

  targetFolder: any = {};
  folderList: Folder[] = [];
  folderType = FOLDER_TYPE;
  isShowFolder: boolean = false;
  customNameColumn: string;
  groupedPeriod: any;
  backActionFromBrowser: boolean = false;
  routerSub: Subscription;
  periodSelected: any = null;
  periods: any[] = []
  columnFilterGrps: any[] = [];
  listRangeValues: any[] = [];
  dslist: string[];
  isFirstTimeOpenDialog: boolean = true;
  
  isHaveMaxMinFilter: boolean = false;

  constructor(
    private router: Router,
    private modalService: DialogService,
    private widgetService: WidgetService,
    private widgetResultService: WidgetResultService,
    private loadingService: ProcessLoadingService,
    private officeService: OfficeAPIService,
    private dashboardService: DashboardService,
    private mstService: MstCommonService,
    private datasourceService: DataSourceService,
    private titleService: Title,
    private windowService: WindowService,
    private authenticationService: AuthenticationService,
    private folderService: FolderService,
    private errorHandleService: ErrorHandleService,
    private corpMstService: CorpMstService
  ) {
    const bodyElement = document.getElementsByTagName("body")[0];
    const isDarkMode = bodyElement.classList.contains("navi") || bodyElement.classList.contains("dark") ? true : false;
    if (isDarkMode) {
      this.folderTree.expandIcon = '../../../assets/icons/folder-white.svg';
      this.folderTree.collapseIcon = '../../../assets/icons/folder-opened-white.svg';
    } else {
      this.folderTree.expandIcon = '../../../assets/icons/folder.svg';
      this.folderTree.collapseIcon = '../../../assets/icons/folder-opened.svg';
    }

    this.isWidgetTemplate = this.router.url.includes("widget-template") ? true : false;

    this.routerSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart && event.navigationTrigger === 'popstate') {
        this.backActionFromBrowser = true;
      }
    });
  }


  ngOnDestroy(): void {
    if(this.isCreatingWidgetByTemplate && !this.isCreateWidgetByDSTemplate && this.datasourceCdTemplate != ""){
      if (!this.useExistDatasource) {
        this.datasourceService.deleteUnsedDataSourceTemplate(this.datasourceCdTemplate);
      }
      this.useExistDatasource = false;
    }
    this.apiServiceSubscriber?.unsubscribe();
    window.removeEventListener('click', this.unselectListBox);
    this.routerSub.unsubscribe();
  }

  ngAfterViewInit(): void {
    // make list box lose focus when click outside
    window.addEventListener('click', this.unselectListBox);
  }

  unselectListBox = (event: any) => {
    if (!this.treeDropArea.nativeElement.contains(event.target)) {
      this.pivotConfigSelection = [];
    }

    if (!this.rowsListItem.nativeElement.contains(event.target)) {
      this.rowsListItemSelection = [];
    }

    if (!this.colsListItem.nativeElement.contains(event.target)) {
      this.colsListItemSelection = [];
    }

    if (!this.valueListItem.nativeElement.contains(event.target)) {
      this.valueListItemSelection = [];
    }
    if (!this.valueFooterItem.nativeElement.contains(event.target)) {
      this.footerSelection = [];
    }
    if (!this.valuesummaryColumnItem.nativeElement.contains(event.target)) {
      this.summaryColumnSelection = [];
    }

  }

  async ngOnInit() {
    this.loadingSomething('initWidget');
    this.modalFilterGrp.style = { width: '400px', 'min-width': '400px', height: '275px' };
    this.modalFilterGrp.header = COMMON_TEXT.GROUP_TEXT;
    this.errorHandleService.backURLSub.next(ROUTE_PATH.WIDGET_LIST)
    this.apiServiceSubscriber = this.errorHandleService.isByPassConfirmDialog.subscribe((isSkipAllConfirmDialog: boolean) => {
      if(this.isByPassConfirmDialog != isSkipAllConfirmDialog) this.isByPassConfirmDialog = isSkipAllConfirmDialog;
    })
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SCREEN_INIT_FAIL);
    this.isSupporterAdmin = await this.authenticationService.isAdminOrSupporter();
    this.nativeWindow = this.windowService.nativeWindow;
    this.modalSettingTarget.style = { width: '1200px', height: '900px', 'min-width': '600px' };
    let current = this.widgetService.pick() as widget

    if (!current) {
      if (this.isWidgetTemplate) {
        this.navigateTo(ROUTE_PATH.WIDGET_TEMPLATE_LIST);
      } else {
        this.navigateTo(ROUTE_PATH.WIDGET_LIST);
      }
      return
    }

    await this.fetchAllFolder();
    this.initLayout()
    let widgetTemplate = this.widgetService.getCreatingByTemplate() as any;
    if(widgetTemplate && widgetTemplate.widgetcdTemplate) {
      this.isCreatingWidgetByTemplate = true;
      this.widget.widgetcd = widgetTemplate.widgetcdTemplate;
      this.datasourceCdTemplate = widgetTemplate.dsStructCd || "";
      this.useExistDatasource = widgetTemplate.useExistDs;
    } 
    else {
      this.widget.widgetcd = current.widgetCd
    }

    let widgetSelected = this.folderList.filter(folder => folder.folderCd == current.folderCd);
    if(widgetSelected.length > 0) {
      this.targetFolder = widgetSelected[0] || {};
    }

    this.widget.foldercd = this.targetFolder.folderCd;
    this.mode = current.mode
    this.setModeInputName();

    let titles = ['', WIDGET_SETTING.WIDGET_CREATE, WIDGET_SETTING.WIDGET_EDIT, WIDGET_SETTING.WIDGET_VIEW]
    let titlesTemplate = ['', WIDGET_TEMPLATE_SETTING.WIDGET_CREATE, WIDGET_TEMPLATE_SETTING.WIDGET_EDIT, WIDGET_TEMPLATE_SETTING.WIDGET_VIEW]
    this.pageTitle = this.isWidgetTemplate ? titlesTemplate[this.mode] : titles[this.mode]
    this.titleService.setTitle(this.pageTitle)

    this.waiter$.subscribe(() => {
      if (this.holder.dslist && this.holder.treeNodes) {
        this.loadFolderTree(this.holder.dslist)
      } else {
        this.loadFolderTree()
      }
    })
    if (this.widget.widgetcd) {
      this.loadingSomething('getWidget')
      this.widgetService.getWidgetConfig(this.widget.widgetcd, this.isCreatingWidgetByTemplate).then(res => {
        if (res.statuscode === 200 && res.data) {
          if(res.data.widgetcd) {
            let config =  {
              id: res.data.id,
              targetTable: res.data.targetconfig != "" ? JSON.parse(res.data.targetconfig) : null,
            }
            this.settingValueConfig = config;
            if(res.data.graphconfig) {
              this.isSavedGraphConfig = true;
              this.graphConfig = res.data.graphconfig != "" ? JSON.parse(res.data.graphconfig) : null
            }
          }
        }
      });

      const api = this.isWidgetTemplate || this.isCreatingWidgetByTemplate ? this.widgetService.getWidgetTemplate(this.widget.widgetcd) :  this.widgetService.getWidget(this.widget.widgetcd);
        api
        .then(async res => {
          let data = res.data as any[]
          this.dataSourceIsDeleted = distinctBy(data, 'dsdelflg').filter(dlg => {
                          const datasourceCd = data.find(e => e.dsdelflg === dlg)?.datasourcecd;
                          return datasourceCd && ![DSCUSTOM, FooterName, SummaryColumnName].includes(datasourceCd);
                        });
          if(res.statuscode != 200) {
            if(this.isWidgetTemplate || this.isCreatingWidgetByTemplate)
              this.errorHandleService.backURLSub.next(ROUTE_PATH.WIDGET_TEMPLATE_LIST)
            else 
              this.errorHandleService.backURLSub.next(ROUTE_PATH.WIDGET_LIST);
          }

          if(this.isCreatingWidgetByTemplate ){
            data.map((item: any) => item.datasourcecd = (item.datasourcecd == "DS-CUSTOM" || item.datasourcecd == "DS-TARGET")   ? item.datasourcecd : this.datasourceCdTemplate);
          }

          let widgetFolder = this.folderList.filter(folder => folder.folderCd == data[0].foldercd);
          if(widgetFolder.length > 0) {
            this.targetFolder = widgetFolder[0] || {};
          }
        
          this.hasWidgetSetting = data.every(x=> x.issettingtarget === true);
          await this.loadWidget(data);

          this.dslist = distinctBy(data, 'datasourcecd');
          this.isFirstTimeOpenDialog = false;
          await this.loadDSFolder();
        })
        .finally(() => this.loadingSomething('getWidget', false))
    }

    this.mstService.getMstByType(MstFilterType.Filter)
      .then(res => {
        let data = res.data as MstFilter[]    
        if(data.length > 0) {
          let modifiedArray = [...data];
          let filterNames = FilterValueOption.map(s=>s.name);
          modifiedArray = modifiedArray.filter(item => !filterNames.includes(item.content)).concat(orderBy(modifiedArray, 'itemcd').filter(item => filterNames.includes(item.content)));
          this.filterOptions = modifiedArray.filter(x => x.itemcd != '0')
            .map(x => ({
              name: x.content,
              value: [x.groupcd, x.itemcd].join('-'),
              type: x.groupcd
            }))
        }
      })
    this.officeList = await this.officeService.getAllOfficeService();
    this.loadingSomething('initWidget', false);
  }

  async getPeriodGroup() {
    const corpMstService = await this.corpMstService.getAll();
    if (corpMstService.statuscode == 200) {
      let monthSetting = corpMstService.data?.find((x:any) => x.contentcd == "0001");
      this.moneyMonth = parseInt(monthSetting?.value || '1');
      let filterBodyRequest = {
        startmonth: this.moneyMonth
      }
      return await this.mstService.getMstFilter(filterBodyRequest) as any[];
    }
    return null;
  }

  initLayout() {
    const defaultParam = { itemDisplayText: 'name', items: [] }

    this.cols = { ...defaultParam, options: PivotOptions }
    this.rows = { ...defaultParam, options: PivotOptions }
    this.value = { ...defaultParam, options: PivotValueOptions }
    this.footers = { ...defaultParam, options: PivotFooterOptions }
    this.hiddens = { ...defaultParam, options: PivotOptions }
    this.summaryColumns = { ...defaultParam, options: PivotSummaryColumnOptions }


    this.footerItem = {
      columnname: FooterName,
      columntype: ColumnType.Footer,
      formattype: PivotFooterOptions[0].value,
      datasourcecd: FooterName,
      columnid: v4(),
      displayname: FooterName,
      pivotorder: 999,
      sortno: 999,
      utcsortno: 999,
      delflg: false,
    }

    this.summaryColumnItem = {
      columnname: SummaryColumnName,
      columntype: ColumnType.summaryColumn,
      formattype: PivotSummaryColumnOptions[0].value,
      datasourcecd: SummaryColumnName,
      columnid: v4(),
      displayname: SummaryColumnName,
      pivotorder: 999,
      sortno: 999,
      utcsortno: 999,
      delflg: false,
    }

    this.widgetdetails.push(this.footerItem, this.summaryColumnItem)
  }

  //#region   Logic Handler

  loadFolderTree(selectedDS: string[] = []) {
    selectedDS = selectedDS.filter(x => ![DSCUSTOM, FooterName, SummaryColumnName, DSTARGET].includes(x))
    const { treeNodes } = this.holder
    const hierarchyCD: bycd[] = [
      { cd: 'foldercd', labelcd: 'foldername', showIcon: true, },
      { cd: 'datasourcecd', labelcd: 'dsname', checkbox: true, isLoaded: true, isLastestNode: true},
      { cd: 'columnname', labelcd: 'displayname', isLoaded: true }
    ]
    let nodes = TreeUtils.arrayToTreeNode(treeNodes, hierarchyCD, selectedDS)
    if(nodes) nodes = orderBy(nodes, ["label"]);
    this.folderTree.nodes = nodes
    this.folderTree = { ...this.folderTree }
  }

  loadSpecialNode(columnList: any[]) {
    const hierarchyCD: bycd[] = [
      { cd: 'foldercd', labelcd: 'foldername', showIcon: true, },
      { cd: 'datasourcecd', labelcd: 'dsname', checkbox: true, isLoaded: true, isLastestNode: true },
      { cd: 'columnname', labelcd: 'displayname', isLoaded: true }
    ]

    this.specialNode = TreeUtils.arrayToTreeNode(columnList, hierarchyCD)
  }

  async fetchAllFolder() {
    try {
      let folderResponse = this.isWidgetTemplate ? await this.folderService.getFolderTemplateByType(FOLDER_TYPE.Widget.toString()) 
                                  : await this.folderService.getByType(FOLDER_TYPE.Widget.toString(), this.isSupporterAdmin);
      if(folderResponse && folderResponse.statuscode && folderResponse.statuscode == 200) {
        this.folderList = folderResponse.data || [];
      }
    } catch(error) {
      //do nothing
    }
  }

  closeFolderModal() {
    this.isShowFolder = false;
  }

  changeFolder(eventData: any) {
    if(eventData != null) {
      this.targetFolder = eventData || {};
    }
  }

  showFolderModal() {
    this.isShowFolder = true;
  }

  takeMissingTTDColsFromDatasourceTemplate(data: any[]) {
    let handleData: any[] = cloneDeep(data);
    let isFormatDifferent: boolean = false
    let templateTTDFormat: any = undefined;
    let existTTDColNameList: string[] = handleData.map((ele: any) => {
      if(ele.columnname.toUpperCase().startsWith("TTD_")) {
        templateTTDFormat ??= ele.columnname;
        return ele.columnname;
      }
      else
      return ""
    }).filter(e => e != "");

    if(handleData && handleData.length > 0) {
      let allTTDColsBySelectedDatasource = this.holder.treeNodes.filter((e: any) => e.datasourcecd == this.datasourceCdTemplate && e.columnname.toUpperCase().startsWith("TTD_"));
      if(allTTDColsBySelectedDatasource. length > 0) {
        isFormatDifferent = templateTTDFormat
        && allTTDColsBySelectedDatasource[0].columnname.length !== templateTTDFormat.length;
        
        allTTDColsBySelectedDatasource.forEach((d: any) => {
          if(!existTTDColNameList.includes(d.columnname)) {
            const existTTDSetting = cloneDeep(handleData.find(e =>  e.columnname.toUpperCase().startsWith("TTD_") 
            && e.columnname.toUpperCase().endsWith(d.columnname.slice(-2))));

            if(existTTDSetting && !isFormatDifferent) {
              handleData.push({
                ...existTTDSetting,
                columnid: d.columnid, 
                columnname: d.columnname, 
                delflg: false
              });
            }
            else {
              const widgetDetailTemplate: any = cloneDeep(handleData[0]);
              handleData.push({
                ...widgetDetailTemplate,
                columnid: d.columnid,
                columnname: d.columnname,
                displayname: d.displayname,
                sortno: 999,
                columntype: 0,
                filtervalue: undefined,
                filtertype: undefined,
                footertype: undefined,
                formattype: undefined,
                operator: undefined,
                delflg: false,
              });
            }
          }
        })
      }
    }
    return handleData;
  }

  async loadWidget(data: any[]) {
    this.widget.id = this.isCreatingWidgetByTemplate ? null :  data[0].wid
    this.widget.widgetname = data[0].widgetname
    this.widget.widgetdesc = data[0].widgetdesc
    this.widget.charttype = data[0].charttype === -1 ? null : data[0].charttype
    this.widget.insdate = data[0].insdate
    this.widget.insstfcd = data[0].insstfcd
    this.widget.sortcoltype = data[0].sortcoltype;
    if(!Utils.isNullOrEmpty(this.widget.sortcoltype))  this.sortParam = JSON.parse(this.widget.sortcoltype || '');
    this.widgetdetails = data.map(x =>  new WidgetDetail(x))
    this.widgetdetails.filter((x: any) => x.columnname?.includes(ColFilterGroup)).map(x => {
        let columnMap = this.widgetdetails?.find(s => s.columnname == x.columnname?.replace(this.colFilterStr, ''));
        if(columnMap) x.displayname = columnMap.displayname;
    })  // map display name for column filter group

    this.widgetdetails.filter(x => !x.utcsortno)
      .sort((a, b) => (a.insdate as Date) > (b.insdate as Date) ? 1
        : a.displayname > b.displayname ? 1
          : -1)
      .map((x, i) => {
        x.utcsortno = i
        return x;
      })

    this.widgetdetails.filter((x: any) => x.utcdelflg).map(x => x.delflg = true)  // remove deleted column from datasource setting
    if (this.widgetdetails.findIndex(x => x.columntype == ColumnType.summaryColumn) == -1) this.widgetdetails.push(this.summaryColumnItem)
    if (!this.dataSourceIsDeleted[0] && ( this.dataSourceIsDeleted[0] != null && this.dataSourceIsDeleted[0] != undefined ) ) {
      this.updateUsing()
      this.updateChoosenTree()
      await this.loadDatasource()
      if(this.isCreatingWidgetByTemplate) {
        data = this.takeMissingTTDColsFromDatasourceTemplate(data);
        this.widgetdetails = this.widgetdetails.map((w: any) => {
          if(w.groupfilterval) {
            this.isFilterGroup = true
            w.bkdatatype = w.datatype;
          }
          if(w.filtertype && this.filterArr.includes(w.filtertype)) {
            let type = w.filtertype.split('-')[1]
            w.filtervalue =  filterByFinancialYearPeriod(type, this.groupedPeriod);
          }
          return w;
        });
      }
      let publicDate = data[0].publicdate;
      if(publicDate) {
        let range = publicDate.split('~');
        this.selectedRangeDate = {startDate: range[0], endDate: range[1].toUpperCase() == "未定" ? null : range[1]}

        if (range[0]) {
          this.startDate = new Date(range[0].replace(/\//g, '-').replace(' ', 'T'))
        } else {
          this.startDate = null;
        }
        if (range[1].toUpperCase() !==  "未定") {
          this.endDate = new Date(range[1].replace(/\//g, '-').replace(' ', 'T'))
          this.isDisplayEdate = true;
        } else {
          this.endDate = null;
          this.isDisplayEdate = false;
        }
      } else {
        this.startDate = new Date();
        this.endDate = null;
        this.isDisplayEdate = false;
      }
    }
  }

  async loadDatasource() {
    //if choose other datasource after choosing template then reset this.isCreatingWidgetByTemplate = false
    let dsNormal: any = [];
    if(!Utils.isNullOrEmpty(this.newSelectedDatasourceCd)){
      if(this.isWidgetTemplate) {
        dsNormal = distinctBy(this.usingWidgetDetails, 'datasourcecd').filter(x => x && ![DSCUSTOM, FooterName, SummaryColumnName, DSTARGET].includes(x));
      }
      else {
        if(this.isCreatingWidgetByTemplate ) {
          this.isUsingDSTemplate =  this.datasourceCdTemplate != this.newSelectedDatasourceCd ? false : true;
        }
        dsNormal = [this.newSelectedDatasourceCd];
      }
    }
    else {
      this.isUsingDSTemplate = this.isCreatingWidgetByTemplate;
      dsNormal = distinctBy(this.usingWidgetDetails, 'datasourcecd').filter(x => x && ![DSCUSTOM, FooterName, SummaryColumnName].includes(x));
    }
    this.datasourceCDs = this.isUsingDSTemplate ? [this.datasourceCdTemplate] : dsNormal ;
    if(this.datasourceCDs?.length == 0) return;
    this.loadingSomething('loadDatasource')
    await Promise.all([
      //this.widgetService.getDataSourceTable(this.datasourceCDs, this.isCreateWidgetByDSTemplate),
      this.datasourceService.getDataSourceFromS3(this.datasourceCDs[0], this.isCreateWidgetByDSTemplate ),
      this.widgetService.getColumnList(this.datasourceCDs[0]),
      this.datasourceService.getDataSourceStructByCode(this.datasourceCDs[0]),
      this.getPeriodGroup()
    ])
      .then(res => {
        const [dsRes, columnList, periodDts, periodList] = res;
        this.sourceTable = dsRes.data as any[]; 
        this.periodSelected = periodDts.data?.period as any
        this.periods = periodList as any[];
        this.groupedPeriod = periodList as any[];
        this.usingWidgetDetails.filter(x => x.datasourcecd === DSCUSTOM).map(x => pushColumnToTable(this.sourceTable, x))
        this.usingWidgetDetails.filter(x => x.columnname.includes(ColFilterGroup)).map(x => this.pushColumnFilterToTable(this.sourceTable, x))
        this.loadSpecialNode(columnList.data || [])
        this.updateChoosenTree()
    
        this.updatePivotConfig()
        //map datasourcesetting id for widgetdetail.columnid to save the widget to display column name correctly

        this.widgetdetails = this.widgetdetails.map((item: any) => {
          if(item.columnname.startsWith("TTD_") ) {
            item.delflg = true;
            return item;
          }
          if(item.groupfilterval) {
            item.bkdatatype = item.datatype;
            this.isFilterGroup = true;
          }
          if (this.isCreatingWidgetByTemplate || item.columnname.startsWith("TTD_")) {
            let match = columnList.data.filter((col: any) => item.columnname == col.columnname);
            if (match.length > 0) {
              let datatype = match[0].datatype;
              if (['EDATE', 'SDATE'].includes(match[0].columnname)) {
                datatype = DATATYPE.Date;
              }
              return { ...item, displayname: match[0].displayname, columnid: match[0].columnid, datatype: datatype };
            }
            return item;
          }
          else return item;
        }).filter(Boolean) as WidgetDetail [];
        
        const hasTTD_WidgetDetails = cloneDeep(this.widgetdetails.filter(item => item.columnname.startsWith("TTD_") ));
        let ttdListItems = this.widgetdetails.filter((e: any) => e.columnname.startsWith("TTD_"));
        if(hasTTD_WidgetDetails.length) {
          //clone some attributes
          let ttdWidgetInfor = hasTTD_WidgetDetails[0];
          if(ttdWidgetInfor ) {
            let ttdDSSetting = columnList.data.filter((col:any) => col.columnname.startsWith("TTD_"));
            ttdDSSetting.forEach((item: any) => {

              let currentTTD = ttdListItems.filter((e: any) => e.columnname === item.columnname);

              ttdWidgetInfor.id = undefined;
              ttdWidgetInfor.datasourcecd = item.datasourcecd;
              ttdWidgetInfor.columnname = item.columnname;
              ttdWidgetInfor.displayname = item.displayname;
              ttdWidgetInfor.columnid = item.columnid;
              ttdWidgetInfor.columntype = currentTTD.length > 0 ? currentTTD[0].columntype : 0;
              ttdWidgetInfor.datatype = "INT";
              ttdWidgetInfor.delflg = false;
              ttdWidgetInfor.sortno = currentTTD.length > 0 ? currentTTD[0].sortno : 999;
              ttdWidgetInfor.pivotorder = currentTTD.length > 0 ? currentTTD[0].pivotorder : null;
              ttdWidgetInfor.utcsortno = currentTTD.length > 0 ? currentTTD[0].utcsortno : ttdWidgetInfor.utcsortno;
              ttdWidgetInfor.filtertype = currentTTD.length > 0 ? currentTTD[0].filtertype : undefined;
              ttdWidgetInfor.filtervalue = currentTTD.length > 0 ? currentTTD[0].filtervalue : undefined;
              ttdWidgetInfor.footertype = currentTTD.length > 0 ? currentTTD[0].footertype : undefined;
              ttdWidgetInfor.formattype = currentTTD.length > 0 ? currentTTD[0].formattype : undefined;
              ttdWidgetInfor.groupfilterval = currentTTD.length > 0 ? currentTTD[0].groupfilterval : undefined;
              ttdWidgetInfor.pivotfiltertype = currentTTD.length > 0 ? currentTTD[0].pivotfiltertype : undefined;
              ttdWidgetInfor.pivotfiltervalue = currentTTD.length > 0 ? currentTTD[0].pivotfiltervalue : undefined;
              ttdWidgetInfor.groupfilterval = currentTTD.length > 0 ? currentTTD[0].groupfilterval : undefined;
              ttdWidgetInfor.rounding = currentTTD.length > 0 ? currentTTD[0].rounding : undefined;
              ttdWidgetInfor.utcdelflg = currentTTD.length > 0 ? currentTTD[0].utcdelflg : undefined;
              ttdWidgetInfor.operator = currentTTD.length > 0 ? currentTTD[0].operator : undefined;
              this.widgetdetails.push({...ttdWidgetInfor});
            } );
          }
        }
        
        this.usingWidgetDetails = this.widgetdetails.filter(x =>  !x.delflg);
        this.runFiltering();
        this.updateUsing();
        
        const chart = (!this.rows.items.length && !this.cols.items.length) || !this.value.items.length ? null : this.widget.charttype
        this.onClickChart(chart)
      })
      .catch(console.error)
      .finally(() => this.loadingSomething('loadDatasource', false))
  }


  loadCustomNode() {
    let cwidget = this.usingWidgetDetails.filter(x => x.datasourcecd === DSCUSTOM).map(x => {
      let types = [...new Set(formulaToOperator(x, 'operator', this.widgetdetails)
        .filter(x => x.type === 'column')
        .map(x => (x.value as WidgetDetail)?.datatype))]

      if (types.length > 1) {
        x.datatype = DATATYPE.String
      } else {
        var type = types.pop()
        if (type) {
          x.datatype = type
        }
        else {
          if(x.operator) {
            x.operator.search(/[a-zA-Z]/) === -1 ? x.datatype = DATATYPE.Number : DATATYPE.String;
          } 
        }
      }
      if(formulaToOperator(x, 'operator', this.widgetdetails).filter(x => x.type === 'operator_custom').length > 0){
        x.datatype = DATATYPE.Number;
        if(x.operator.includes("IF"))   x.datatype = DATATYPE.String;
      }
      return x
    })

    let customs: TreeNode[] = cwidget.map(x => ({ id: x.columnname, label: x.displayname, draggable: true, isLastNode: true, canDelete: true, data: [x] }))
    let customTargets: TreeNode[] = this.usingWidgetDetails.filter(x => x.datasourcecd === DSTARGET).map(x => ({ id: x.columnname, label: x.displayname, draggable: true, isLastNode: true, canDelete: true, data: [x] }))
    if (customs.length > 0 || customTargets.length > 0) {
      this.customNode = this.createCustomNode()
      this.customNode.nodes = [...customs, ...customTargets]
    } else {
      this.customNode = null
    }
  }

  updateChoosenTree(customNodeOnly = false) {
    this.loadCustomNode()

    let nodes = this.customNode ? [this.customNode] : []

    if (customNodeOnly) {
      this.choosenDSTree.nodes.length > 1 && this.choosenDSTree.nodes.pop()
      this.choosenDSTree.nodes = [
        ...this.choosenDSTree.nodes,
        ...nodes
      ]
    } else if (this.specialNode.length) { // load specialNode then remove it after first load
      this.choosenDSTree.nodes = [
        ...this.specialNode
          .map(x => x.nodes!)
          .flat(1)
          .map(treeNode => {
            const cloneTreeNode = { ...treeNode }
            const remapNode = this.isCreatingWidgetByTemplate ? treeNode.nodes : treeNode.nodes?.filter(y => this.detailsBag[y.id!]);
            cloneTreeNode.nodes = remapNode

            return cloneTreeNode
          }),
        ...nodes
      ]
      this.specialNode = []
    } else {    // normal flow for udpate tree
      this.choosenDSTree.nodes = [
        ...this.folderTree.nodes
          .map(x => (x.nodes!)
          .filter(y => y.singleSelected))
          .flat(1)
          .map(treeNode => {
            const cloneTreeNode = { ...treeNode, singleSelected: false }
            const remapNode = this.isCreatingWidgetByTemplate ? treeNode.nodes : treeNode.nodes?.filter(y => this.detailsBag[y.id!])
            cloneTreeNode.nodes = remapNode

            return cloneTreeNode
          }),
        ...nodes
      ]
    }

    this.choosenDSTree = cloneDeep(this.choosenDSTree)
  }

  onShowListOption(event: any) {
    if (this.isShowOption) {
      this.listOp.toggle(event);
    }
  }

  getDateRangeByPeriod(periods: any[]) {
    this.widgetdetails = this.widgetdetails.map(w => {
      if(w.filtertype && w.filtervalue && !w.delflg && w.datatype == DATATYPE.Date) {
        // update date range for date current
        let groupFilters = w.filtertype?.split('-');
        let periodArr = this.periodSelected?.split('-');
        if(groupFilters.length > 1) {
          let findPeriod = periods?.find(s=>s.value == periodArr[0]);
          let rangeDate: any = null;
          if(findPeriod) {
            if(this.filterArr.includes(w.filtertype)) {
              rangeDate =  filterByFinancialYearPeriod(groupFilters[1], this.groupedPeriod);
              w.filtervalue = rangeDate;
            }
            else if(this.filterDateCurrent.includes(w.filtertype)) {
              let dateCurrent = getDayOfWeekOrMonthCurrent(groupFilters[1]);
              rangeDate = findPeriod.items?.find((p: any) => p.value == this.periodSelected || '');
              w.filtervalue = moment(rangeDate?.startdate).format(DateFormat.FULL_SHORT_DATE) + ' - ' + moment(dateCurrent?.end).format(DateFormat.FULL_SHORT_DATE)
            }   
          }
          else {
            rangeDate = this.periodSelected.split('~');
            if(rangeDate.length > 1 && this.filterDateCurrent.includes(w.filtertype)) {
              let dateCurrent = getDayOfWeekOrMonthCurrent(groupFilters[1]);
              w.filtervalue = moment(rangeDate[0]).format(DateFormat.FULL_SHORT_DATE) + ' - ' + moment(dateCurrent?.end).format(DateFormat.FULL_SHORT_DATE)
            }
            else {
              w.filtervalue = getRange(groupFilters[1], this.periodSelected, this.periods);
            }
          }
        }
      }
      return w;
    });
  }


  onSelectedOption(option: any) {
    this.selectOption = option.value
    this.value.items?.forEach((vl: any) => {
    if(!(vl.datasourcecd === DSTARGET || (vl.datasourcecd === DSCUSTOM && checkACustomColumnContainTargetColumns(vl, this.settingValueConfig?.targetTable )))) {
      const w = this.widgetdetails.filter(x => x.columnname === vl.columnname && x.delflg ==false).pop()!
        if(w) {
          if(w.formattype?.includes('A')) {
            if(w.formattype?.includes(FormatType.Group)) w.formattype = w.formattype?.replace(FormatType.Group, '');
            if(w.formattype?.includes('B')) {
              let index = w.formattype.indexOf('B');
              if(index != -1) {
                w.formattype = w.formattype.substring(0, index) + option.value?.value + w.formattype.substring(index + 2);
              }
            }
            else w.formattype = w.formattype + option.value?.value;
          }
          else {
            w.formattype = option.value?.value;
          }
        }
      }
    })
    this.updatePivotConfig()
    this.runFormating()
    this.runPivoting()
    this.generateTable()
    this.listOp.hide();
  }

  updateDetails() {
    this.widgetdetails.filter(x => x.columntype !== ColumnType.Footer &&  x.columntype !== ColumnType.summaryColumn).map(x => x.delflg = true)
    const details = this.folderTree.nodes.map(x => x.nodes?.filter(y => y.singleSelected).map(y => y.nodes?.map(z => z.data))).flat(3)
    const oldDS = [...new Set(this.usingWidgetDetails.map(x => x.datasourcecd).filter(x => ![DSCUSTOM, FooterName, SummaryColumnName].includes(x)))].pop()
    const newDS = [...new Set(details.map(x => x.datasourcecd).filter(x => ![DSCUSTOM, FooterName, SummaryColumnName, oldDS].includes(x)))].pop()
    let notHasYet = newDS ? details : details.filter(x => !this.detailsBag[x.columnname])
    //const alreadyHas = newDS ? [] : details.filter(x => this.detailsBag[x.columnname])
    //const seekerHas = alreadyHas.reduce((a, b) => (a[b.columnname] = true, a), {})
    this.newSelectedDatasourceCd = newDS ? newDS : oldDS;
    this.usingWidgetDetails.forEach(x => {
      if(x.columntype == ColumnType.Footer || x.columntype == ColumnType.summaryColumn && !x.delflg)  {
        x.datasourcecd = x.columntype == ColumnType.Footer ? FooterName: SummaryColumnName
        x.pivotorder = 999
      }
    })
    if (!newDS || oldDS === newDS) {
      notHasYet = details.filter(x => this.detailsBag[x.columnname]);
      //this.widgetdetails.map(x => seekerHas[x.columnname] && (x.delflg = false))
    } else {
      if (this.customNode) {
        this.choosenDSTree.nodes.pop()
      }
    }


    this.widgetdetails = [
      ...this.widgetdetails,
      ...notHasYet.map(x => new WidgetDetail(x)),
    ].sort((a, b) => a.utcsortno - b.utcsortno)

    this.updateUsing()
  }

  updateUsing() {
    // map datatype for list column filter group
    this.widgetdetails = this.widgetdetails.map(col => {
      if(col.columnname?.includes(this.colFilterStr)) {
        let columnMap = this.widgetdetails.find(s =>s.columnname == col.columnname.replace(this.colFilterStr, ''));
        if(columnMap) col.datatype = columnMap.datatype;
      }
      return col;
    })
    this.usingWidgetDetails = this.widgetdetails.filter(x => !x.delflg)
    this.detailsBag = {}
    this.usingWidgetDetails.map(x => this.detailsBag[x.columnname] = x)
    this.updatePivotConfig()
  }

  onDropHiddenColumn(selectedCol: any) {
    let isFilterCols = this.widgetdetails?.filter(x => x.columnname == selectedCol.columnname 
        && (!InRow[x.columntype]) 
        && (!InColumn[x.columntype]) 
        && (!InValue[x.columntype]))?.length > 0 ? true : false;
    if(!isFilterCols || !selectedCol) return
    // remove hidden column
    let colIsHidden = this.hiddens?.items?.filter(s=> s.value == selectedCol.columnname)?.length > 0 ? true : false;
    this.widgetdetails = this.widgetdetails.map(col => {
      if(col.columnname == selectedCol?.columnname  && !col.delflg && colIsHidden) {
        col.columntype = ColumnType.InUse;
      }
      return col
    })
    this.usingWidgetDetails = this.widgetdetails.filter(x => !x.delflg)
    this.hiddens.items = this.usingWidgetDetails?.filter(s => InHiddenValue[s.columntype])?.map(x => ({
      value: x.columnname, name: replaceNameNashi(x.displayname),
      ...x}))
  }

  updatePivotConfig() {
    const using = this.usingWidgetDetails.filter(x => x.columntype)
    const { rows, columns, values, footers, summaryColumns, hiddens } = makePivotConfig(using)
    this.selected.items = using.filter(x => x.columntype !== ColumnType.Footer && x.columntype !== ColumnType.summaryColumn && x.columntype !== ColumnType.Hidden_Value && !x.columnname?.includes(ColFilterGroup))
      .map(x => ({ value: x.columnname, name: replaceNameNashi(x.displayname), type: x.datatype, ...x }))
      .sort((a, b) => a.sortno - b.sortno)
    rows?.sort((a: any,b: any) => {
      return a.pivotorder - b.pivotorder
    });
    this.rows.items = rows.map((x: any) => ({
      value: x.columnname, name: replaceNameNashi(x.displayname),
      defaultValue: x.datatype === DATATYPE.Date || x.datatype === DATATYPE.Float || x.datatype === DATATYPE.Number || x.groupfilterval || x.bkdatatype 
        ? PivotOptions.filter(y => y.value === selectType(x.formattype, 'A')).pop() || PivotOptions[0]
        : null,
      ...x,
    }))
    columns?.sort((a: any,b: any) => {
      return a.pivotorder - b.pivotorder
    });
    
    this.cols.items = columns.map((x: any) => ({
      datatype: x.bkdatatype ? x.bkdatatype: x.datatype,
      value: x.columnname, name: replaceNameNashi(x.displayname),
      defaultValue: x.datatype === DATATYPE.Date || x.datatype === DATATYPE.Float || x.datatype === DATATYPE.Number || x.groupfilterval || x.bkdatatype 
        ? PivotOptions.filter(y => y.value === selectType(x.formattype, 'A')).pop() || PivotOptions[0]
        : null,
      ...x,
    }))

    values?.sort((a: any,b: any) => {
      return a.pivotorder - b.pivotorder
    });
    this.value.items = values.map(x => {
      let noOptionsAndFilter = false;
      if(x.operator) {
        if(this.settingValueConfig && this.settingValueConfig.targetTable)
          noOptionsAndFilter = checkACustomColumnContainTargetColumns(x, this.settingValueConfig.targetTable);
      }
      return ({
      value: x.columnname, name: replaceNameNashi(x.displayname), isFilter: x.datasourcecd === DSTARGET || noOptionsAndFilter ? false: true, onFilter: x.pivotfiltertype ? true : false,
      defaultValue: x.datasourcecd === DSTARGET || noOptionsAndFilter ? null : PivotValueOptions.filter(y => y.value === selectType(x.formattype, 'B')).pop() || PivotValueOptions[0],
      ...x,
    })})

    this.footers.items = footers.map(x => ({
      value: x.columnname, name: ' ',
      defaultValue: PivotFooterOptions.filter(y => y.value == x.formattype).pop() || PivotFooterOptions[0], ...x
    }))

    this.footerItem = this.footers.items[0] as any as WidgetDetail
    this.footerType = this.footerItem?.formattype

    this.summaryColumns.items = summaryColumns.map(x => ({
      value: x.columnname, name: ' ',
      defaultValue: PivotSummaryColumnOptions.filter(y => y.value == x.formattype).pop() || PivotSummaryColumnOptions[0], ...x
    }))

    this.summaryColumnItem = this.summaryColumns.items[0] as any as WidgetDetail
    this.summaryColumnType = this.summaryColumnItem?.formattype
    this.hiddens.items = hiddens.map(x => ({
      value: x.columnname, name: replaceNameNashi(x.displayname),
      ...x}))

    this.selected.items.forEach((x: any) => {
      if (this.settingValueConfig?.targetTable && x.datasourcecd !== DSTARGET && !Utils.isNullOrEmpty(x.operator)) {
        if (checkACustomColumnContainTargetColumns(x, this.settingValueConfig?.targetTable)) {
          x.isHaveTargetCol = true;
        }
      }
      if (x.datasourcecd === DSTARGET || x.isHaveTargetCol) {
         x.isTargetValue = true;
      } else {
        x.isTargetValue = false;
      }
    });
  }

  generateTable() {
    this.dataSource.header = this.selected.items.map((x: any) => {
      let h = new HeaderItem()
      h.field = x.value
      h.title = x.name
      h.dataType = x.datatype
      return h;
    })

    this.dataSource.body = this.filteredTable;
    this.dataSource = { ...this.dataSource }
  
  }

  createCustomNode(): TreeNode {
    if (!this.customNode) {
      this.customNode = new TreeNode()
      this.customNode.isShowIcon = false
      this.customNode.id = DSCUSTOM
      this.customNode.label = WIDGET_SETTING.CUSTOM_TREE
      this.customNode.nodes = []
    }
    return this.customNode
  }

  runFiltering() {
    let rangeDate = null;
    if(this.periodSelected && this.isFilterDate()) {
      let groups = this.periodSelected.split('-');
      let findPeriod = this.periods.find(s=>s.value == groups[0]);
      if(findPeriod) {
        rangeDate = findPeriod.items?.find((p: any) => p.value == this.periodSelected);
      }
      else {
        groups = this.periodSelected.split('~');
        if(groups?.length > 1) rangeDate = { startdate : groups[0], enddate: groups[1] }
        else rangeDate = { startdate : groups[0], enddate: groups[0] }
      }
    }
    this.filterParams = this.usingWidgetDetails.filter(x => x.filtertype && !x.columnname.includes(this.colFilterStr))

    let minOrMaxColFilter = this.filterParams?.find((colFilter : any) => {
      let colFilterArr : any = colFilter?.filtertype?.split('-');
      let colFilterType = colFilterArr[0];
      let colFilterCondition = colFilterArr[1];
      if (colFilterType === MstFilterGroup.INT && (colFilterCondition === MstFilterIntItem.Max || colFilterCondition === MstFilterIntItem.Min)) {
        return true
      }
      return false;
    });
    
    this.isHaveMaxMinFilter = minOrMaxColFilter ? true : false;

    if (this.isHaveMaxMinFilter && this.filterParams?.length === 1) {   
      this.filteredTable = filteringMaxMin(this.sourceTable, this.filterParams);
    } else {
      let normalFilter = filtering(this.sourceTable, this.filterParams, rangeDate, this.periods);
      if (this.isHaveMaxMinFilter) {
        this.filteredTable = filteringMaxMin(normalFilter, this.filterParams);
      } else {
        this.filteredTable = normalFilter;
      }
    }
  }
  

  runFormating() {
    this.columnFilterGrps = this.widgetdetails.filter(x =>x?.formattype?.includes(FormatType.Group) && x.columnname?.includes(ColFilterGroup) && !x.delflg) || [];
    this.columnFilterGrps = this.columnFilterGrps.map(col => {
      if(col.columnname?.includes(ColFilterGroup)) {
        let mapColumn = this.widgetdetails?.find(s => s.columnname == col.columnname?.replace(this.colFilterStr, ''));
        col.datatype = mapColumn?.datatype;
      }
      col.groupfilterval = JSON.stringify(this.mapGroupFilterVal(col.groupfilterval || ''));
      return col;
    })
    this.formatedTable = this.filteredTable;
    if(this.isCreatingWidgetByTemplate) this.runPivoting();
  }

  handleSortColTable(data: any) {
    if(data) this.sortParam = data;
  }

  mapConfigWithFilterParams(config: PivotTableConfig): PivotTableConfig {
    config.columns = this.mapConfigColumnWithFilterParams(config.columns);
    config.rows = this.mapConfigColumnWithFilterParams(config.rows);
    config.values = this.mapConfigColumnWithFilterParams(config.values);
    config.footers = this.mapConfigColumnWithFilterParams(config.footers);
    config.summaryColumns = this.mapConfigColumnWithFilterParams(config.summaryColumns);
    config.hiddens = this.mapConfigColumnWithFilterParams(config.hiddens);
    return config;
  }
  
  mapConfigColumnWithFilterParams(cols: WidgetDetail[]): WidgetDetail[] {
    return cols.map(item => {
      const filteredItem = this.filterParams.find(fp => fp.columnname === item.columnname);
      if (filteredItem) {
        item = { ...item, filtertype: filteredItem.filtertype, filtervalue: filteredItem.filtervalue };
      }
      return item;
    });
  }

  mapGroupFilterVal(filterStr: any) {
    if(!filterStr)  return null;
    let filters = JSON.parse(filterStr);
    if(filters) {
      let option = filters.groupFilter?.option || FilterGroupOptions.find(s =>s.value == FilterGroup.YEAR_MONTH);
      let isDate = filters.groupFilter?.isDate || false;
      let isMaxVal = filters.groupFilter?.isMaxValue || false;
      let isMinVal = filters.groupFilter?.isMinValue || false;
      let minValue = filters.groupFilter?.minValue;
      let maxValue = filters.groupFilter?.maxValue;
      let columnName = filters?.columnname || '';
      let range = this.getRangeValue(option, isDate, columnName);
      if(!range) return null;
      if(isMinVal || isMaxVal) {
        if(!Utils.isNullOrEmpty(range.minVal) && isMinVal) minValue = isDate && option?.value != FilterGroup.DAY ? moment(range.minVal).format('YYYY-MM-DD HH:mm').toString() : range.minVal;
        if(!Utils.isNullOrEmpty(range.maxVal) && isMaxVal) maxValue = isDate && option?.value != FilterGroup.DAY ? moment(range.maxVal).format('YYYY-MM-DD HH:mm').toString() : range.maxVal;
        return { groupFilter: { minValue: minValue, maxValue: maxValue, unitValue: filters.groupFilter?.unitValue , isDate: isDate, 
          option: isDate ? option : null, isMinValue: isMinVal, isMaxValue:  isMaxVal }, columnname: filters?.columnname, columntype: filters?.columntype }
      }
      else return filters;
    }
  }

   /* 
  get range Min and Max value
  */
  getRangeValue(option: any, isDate: boolean, columnName: string) {
    let data = this.filteredTable.map((s: any)=>s[columnName?.replace(this.colFilterStr, '')?.trim()]) || [];
    if(data.length == 0) return null;
    let minVal: any = null;
    let maxVal: any = null;
    if(!option) option = FilterGroupOptions.find(s =>s.value == FilterGroup.YEAR_MONTH);
      // set range value for value type Date
      if(isDate) {
        data = data?.filter((value: any) => typeof value === 'string') || [];
        if(data.length > 0) {
          let minMaxCol = findMinMaxValues(data, isDate, option);
          minVal = getValueFromFormatDate(option.value, minMaxCol.minVal);
          maxVal = getValueFromFormatDate(option.value, minMaxCol.maxVal);
        }
        else return null;
      }
      else {
        // set range value of value type number
        data = data?.filter((value: any) => typeof value === 'number' || isDecimal(value)) || [];
        if(data.length > 0) {
          let minMaxValue = minAndMaxArray(data);
          minVal = minMaxValue.min;
          maxVal = minMaxValue.max;
        }
        else return null;
      }
     return { minVal: minVal, maxVal: maxVal };
  }

  

  async runPivoting() {
    this.loadingSomething('runPivoting')
    let config = new PivotTableConfig()
    config.columns = [...this.cols.items] as any as WidgetDetail[]
    config.rows = [...this.rows.items] as any as WidgetDetail[]
    config.values = [...this.value.items] as any as WidgetDetail[]
    config.footers = [...this.footers.items] as any as WidgetDetail[]
    config.footers = config.footers?.filter((s: any)=>s.defaultValue?.value);
    config.hiddens = [...this.hiddens.items] as any as WidgetDetail[]
    config.summaryColumns = [...this.summaryColumns.items] as any as WidgetDetail[]
    config.summaryColumns = config.summaryColumns?.filter((s: any)=>s.defaultValue?.value);
    config.type =  ChartType.COL;
    if(this.columnFilterGrps.length > 0) {
      this.columnFilterGrps = this.columnFilterGrps.map(col => {
        if(col?.groupfilterval && col?.formattype?.toString()?.includes(FormatType.Group)) {
          let groupFilterData =  JSON.parse(col?.groupfilterval); 
          const unit = parseFloat(groupFilterData?.groupFilter?.unitValue);
          let minVal: any = null;
          let maxVal: any = null;
          const option = groupFilterData?.groupFilter?.option;
          if(col.datatype == DATATYPE.Date || option) {
            minVal = groupFilterData?.groupFilter?.minValue;
            maxVal = groupFilterData?.groupFilter?.maxValue;
            let rangeDate = getRangeValueTypeDate(option?.value, minVal, maxVal, unit);
            col.range = rangeDate? JSON.stringify(getRangeValueTypeDate(option?.value, minVal, maxVal, unit)) : null;
          }
          else {
            const minVal = parseFloat(groupFilterData?.groupFilter?.minValue);
            const maxVal = parseFloat(groupFilterData?.groupFilter?.maxValue);
            let numberRanges = getRangesValueNumber(minVal, maxVal, unit);
            col.range = numberRanges? JSON.stringify(numberRanges) : null;
          }
        }
        return col;
      })
    }
    if(this.widget.charttype && this.widget.charttype == GraphType.LINE_BAR_COMBINE_CHART){
      config.type = ChartType.ROW;
    }
    if (this.filterParams) {
      config = this.mapConfigWithFilterParams(config)
    }
    const typeofGraph = ![GraphType.TABLE_CHART, null].includes(this.widget.charttype);
  
    let request = {
      configs: config,
      charttype: this.widget.charttype,
      datasourcecd: this.datasourceCDs[0],
      settingtargets: this.settingValueConfig?.targetTable,
      selecttype: this.widget.charttype == GraphType.TABLE_CHART ? WidgetSelectDataType.TABLEDATA : WidgetSelectDataType.SPECIALCASE
    };
    this.chartData = undefined;
    this.tableData = undefined;
    // is filter target column
    let isFilterCusTomOrTar = this.usingWidgetDetails?.filter(s => !s.delflg && s.filtertype && s.filtervalue && s.datasourcecd == DSTARGET)?.length > 0 ? true: false;
    let isPivoting: boolean = !isFilterCusTomOrTar && request.charttype && (config.columns.length > 0 || config.rows.length > 0 || config.values.length > 0 || config.hiddens.length > 0) ? true : false;
    if(!isPivoting)  {
      this.loadingSomething('runPivoting', false)
      return;
    }
    let result = await this.widgetResultService.pivoting(request);
    if(result.data) {
      let data = result.data;
      if(this.settingValueConfig && this.settingValueConfig.targetTable && this.settingValueConfig.targetTable.length) {
        this.tableData = {table : {...data?.table, body: data?.table?.body}, config: config };
      }
      else {
        this.tableData = {table : data.table, config: config };
        this.tableData?.table?.body?.map((x:any)=> {
          for (let key in x){         
            if (typeof x[key] == 'object') {
              if (typeof x[key]?.value == 'string') {
                x[key].value = x[key].value.replace(/\\/g, "¥");
              }
            }
          }
          return x;
        });
      }
      let dataChart = Utils.replaceYenCharacterOnChart(data?.chart);
      this.chartData = typeofGraph ? dataChart : this.tableData;
      if (this.graphConfig?.length > 0){
        this.isStackedChartSetting = this.graphConfig[0].isStackedChartSetting;
      }else {
        this.isStackedChartSetting = false;
      }
    } 
    else {
      this.tableData = undefined;
      this.chartData = undefined;
    }
    this.loadingSomething('runPivoting', false)
  }
   

  updateFormulaRows(inputFormula: string, rowOffset: number) {
    return inputFormula.replace(/([A-Z]+)(\d+)/g, (match, columnLetters, rowNum) => {
        const newNumber = parseInt(rowNum) + (rowOffset -1);
        return columnLetters + newNumber.toString();
    });
  }

  updateFormulaColumns(inputFormula: string, newRowNumber: number) {
    return inputFormula.replace(/([A-Z]+)(\d+)/g, (match, columnLetters, rowNum) => {
      const newColumnLetters = columnLetters;
      const updatedRowNumber = Math.min(newRowNumber, parseInt(rowNum));
      return newColumnLetters + updatedRowNumber.toString();
    });
  }



  deleteCustomNode(item: WidgetDetail) {
    if (item) {
      const detail = this.widgetdetails.filter(x => x.columnid === item.columnid).pop()!
      detail.delflg = true
      this.customNode!.nodes = this.customNode?.nodes?.filter(x => x.id !== item.columnname)
      this.updateUsing()
      this.updateChoosenTree()
      this.selectedCustom = null
    }
    this.isDisplayDefineColumnDialog = false;
  }

  //#endregion

  //#region   Event Handler

  onDrop(event: DragEvent, pivotColumn?: 'cols' | 'rows' | 'value', isPivoting: boolean = false) {
    let items = JSON.parse(event.dataTransfer!.getData("items"));
    items?.forEach((item: any) => {
      const data = item.data.pop()!;
      const existCol = this.selected.items.filter(i => i.value === item.id);
      const detail = this.widgetdetails.filter(x => x.columnname === item.id && !x.delflg).pop()!;
      if(detail && detail.rounding) detail.datatype = DATATYPE.Float;
      const selectedCol = { value: item.id, name: item.label, ...data };
      const inEdge: any = { cols: true, rows: true, value: false }
      const notExistInValues = this.value.items.every(x => x.value != item.id)
      if (!Utils.isNullOrEmpty(detail.operator) && detail.datasourcecd !== DSTARGET){
        if (checkACustomColumnContainTargetColumns(detail, this.settingValueConfig.targetTable )) {
          detail.isHaveTargetCol = true;
        }
      }
      if (pivotColumn === 'value' && !notExistInValues) return;
      // if column type is DS-TARGET cannot not drop in rows and columns
      if((pivotColumn === 'rows' || pivotColumn === 'cols')  && detail.datasourcecd === DSTARGET) return;
      // if column type is DS-CUSTOM and its operator contains any DS-TARGET columns cannot not drop in rows and columns
      if((pivotColumn === 'rows' || pivotColumn === 'cols') && detail.datasourcecd === DSCUSTOM) {
        if (checkACustomColumnContainTargetColumns(detail, this.settingValueConfig.targetTable )) return;
      }
      if (!existCol.length) {
        if (detail) {
          detail.sortno = this.selected.items.length
          detail.pivotorder = this.selected.items.length
          detail.columntype = detail.columntype === ColumnType.NoUse ? ColumnType.InUse : detail.columntype 
        }
        this.selected.items.push(selectedCol);
        this.selected = cloneDeep(this.selected);
      }
  
      if (pivotColumn) {
        let duplicatedEdge = false;
  
        if (inEdge[pivotColumn]) {
          duplicatedEdge = !!this.rows.items.filter(i => i.value === item.id).length || !!this.cols.items.filter(i => i.value === item.id).length
        }
  
        const defaultValue = PivotOptions.filter(y => y.value === selectType(selectedCol.formattype, 'A')).pop() || this.selectOption
        if (!duplicatedEdge) {
          const item = { ...selectedCol, defaultValue }
          this[pivotColumn].items.push(item)
          this[pivotColumn] = cloneDeep(this[pivotColumn])
          if(detail) {
            if (detail.columntype === ColumnType.InUse) {
              detail.columntype -= ColumnType.InUse
            }
            detail.columntype += this.seek[pivotColumn]
          }
          if (pivotColumn === 'cols' && notExistInValues) {
            this.onDrop(event, 'value', true)
          }
        }
      }
      //filterColumns
      else {
        this.onDropHiddenColumn(selectedCol);
      }
    });
    this.updatePivotConfig()
    this.runFiltering()
    this.runFormating()
    this.runPivoting()
    this.generateTable()
  }

  deleteColumn(removedItem: any, columnType: columntype = 1) {
    const express = (x: any) => x.value !== removedItem.value
    const detail = this.widgetdetails.filter(x => x.columnname === removedItem.value && !x.delflg).pop()!
    if(this.checkWidgetDetailDelete(detail)){
      detail.sortno = 999
    }
    detail.datatype = detail.formattype?.toString()?.includes(FormatType.Group) && detail.datatype == DATATYPE.String ? DATATYPE.Float: detail.datatype; 
    detail.pivotorder = null
    detail.filtertype = undefined
    detail.filtervalue = undefined
    detail.formattype = undefined
    detail.groupfilterval = undefined;
    switch (columnType) {
      case ColumnType.Row: // rows
        this.rows.items = this.rows.items.filter(express)
        detail.columntype -= ColumnType.Row
        break

      case ColumnType.Column: // cols
        this.cols.items = this.cols.items.filter(express)
        detail.columntype -= ColumnType.Column
        break

      case ColumnType.Value: // value
        this.value.items = this.value.items.filter(express)
        let findCol = this.widgetdetails?.find( u => u.columnname == removedItem.columnname && !u.delflg)
        if(findCol) {
          findCol.pivotfiltertype = undefined
          findCol.pivotfiltervalue = undefined
        }
        detail.columntype -= ColumnType.Value
        break

      case ColumnType.InUse:
        this.rows.items = this.rows.items.filter(express)
        this.cols.items = this.cols.items.filter(express)
        this.value.items = this.value.items.filter(express)
        this.selected.items = this.selected.items.filter(express)
        if(InHiddenValue[detail.columntype]) detail.columntype = ColumnType.Hidden_Value
        else detail.columntype = ColumnType.NoUse
        break;

      default:
        break
    }

    // this.mapWidgetSetting(removedItem.value, detail.sortno, detail.columntype);

  }
  checkWidgetDetailDelete(widgetdetail:WidgetDetail):boolean{
    const items = [...this.rows.items.filter((x: any) => x.columnid == widgetdetail.columnid),
    ...this.cols.items.filter((x: any) => x.columnid == widgetdetail.columnid),
    ...this.value.items.filter((x: any) => x.columnid == widgetdetail.columnid)];
    return (items)?(items.length == 0):true;
  }
  async formatData() {
    this.updatePivotConfig()
    this.runPivoting()
    this.generateTable()
  }

  async onDeleteColumn(removedItem: any, columnType: columntype = 1) {
    let headers = this.sortParam?.headers || [];
    for (let i = 0; i < headers?.length; i++) {
      if (headers[i].value === removedItem.displayname) {
        this.sortParam?.sortArr?.splice(i,1);
        this.sortParam?.headers?.splice(i,1);
      }
    }
    if(removedItem?.value?.includes(ColFilterGroup)) {
      this.deleteColumn(removedItem, columnType);
      this.widgetdetails = this.widgetdetails?.map(col => {
        if(col.columnname == removedItem.value) {
          col.delflg = true;
        }
        else if(col.columnname == removedItem.value?.replace(this.colFilterStr, '') && !col.delflg) {
          col.formattype = col.formattype?  col.formattype.replace(FormatType.Group, ''): undefined
        }
        return col;
      });
    }
    else {
      this.deleteColumn(removedItem, columnType);
    }
    
    this.loadingService.isLoading.emit(true);
    this.runFiltering()
    this.runFormating();
    this.updatePivotConfig()
    this.runPivoting()
    this.generateTable()
  }

  onReorder(event: any[], inPivot: boolean = false) {
    event.map((x, i) => {
      var w = this.widgetdetails.filter(y => y.columnname === x.value).pop() as WidgetDetail
      if (!inPivot) w.sortno = i
      else w.pivotorder = i
    })

    this.updatePivotConfig()
    inPivot && this.runPivoting()
    !inPivot && this.generateTable()
  }

  openDefinedColumnDlg(isAddNew: boolean) {
    this.mapDssCdForCol();
    this.definedColModalData.header = WIDGET_SETTING.HEADER1;
    this.definedColModalData.style = { 'width': '70%', 'max-height': '100%'};
    this.definedColModalData.breakpoints = { '1300px': '75vw', '640px': '100vw' };
    if(isAddNew)  this.selectedCustom = null;
    this.isDisplayDefineColumnDialog = true;
  }

  async openDatasourceSelectionDialog() {
    if(this.isFirstTimeOpenDialog) {
      await this.loadDSFolder();
    }
    this.isDisplayDatasourceSelectionDialog = true;
  }

  async loadDSFolder() {
    this.loadingSomething("Init-datasource-tree")
    let reCheckLoadStatus : boolean = false;
    if(this.widget.widgetcd) {
      reCheckLoadStatus = true;
      // Get level 1
      await this.getDatasourceFolderStructByLevel( [], 1);
      // Get level 2-3 of selected datasource
      await this.getDatasourceFolderStructByLevel(this.dslist, 3);
      this.holder = { ...this.holder, dslist: this.dslist }
    }
    else {
      // Get level 1
      await this.getDatasourceFolderStructByLevel( [], 1);
    }
    // Wait for render folder tree
    await new Promise<void>((resolve) => {
      this.waiter$.pipe(take(1)).subscribe(() => {
        resolve();
      });
      this.waiter$.emit();
    });
    this.isFirstTimeOpenDialog = false;
    if(reCheckLoadStatus) this.reMapLoadStatus();
    this.loadingSomething("Init-datasource-tree", false);
  }

  // Init tree logic
  reMapLoadStatus() {
    const folderSelected = this.folderTree.nodes.find(folderNode =>
      folderNode.nodes?.some(node => node.singleSelected)
    );
    if(folderSelected) folderSelected.isLoaded = true;
  }
  async getDatasourceFolderStructByLevel(datasourceCds: string[] = [], level: number) {
    let treeNodes: any[] = [];
    switch(level) {
      case 1:
        treeNodes = await this.getFolderStruct();
        break;
      case 3:
        treeNodes = await this.getColumnsStructOfDatasource(datasourceCds);
        break;
    }

    if (level == 1) {
      this.holder = {...this.holder, treeNodes}
    } else {
      // Ensure this.holder is an object and this.holder.treeNodes is an array
      this.holder = typeof this.holder === 'object' ? this.holder : {};
      this.holder.treeNodes = Array.isArray(this.holder.treeNodes) ? this.holder.treeNodes : [];
      this.holder.treeNodes = 
        [...this.holder.treeNodes, ...treeNodes];
    }
  }

  async getFolderStruct() {
    var result = await this.folderService.getDatasourceFolderStructByLevel(["DS-DEFAULT"], 1, this.isSupporterAdmin, this.isWidgetTemplate);
    return result;
  }

  async getColumnsStructOfDatasource(datasourceCds: string[]) {
    datasourceCds = datasourceCds.filter(x => ![DSCUSTOM, FooterName, SummaryColumnName, DSTARGET].includes(x))
    var result = await this.folderService.getDatasourceFolderStructByLevel(datasourceCds, 3, this.isSupporterAdmin, this.isWidgetTemplate);
    return result;
  }
  
  onClickSwitchButton(){
    this.isStackedChartSetting = !this.isStackedChartSetting;
  }

  async closeDatasourceSelectionDialog(tree: TreeViewInputParams | undefined) {
    this.isDisplayDatasourceSelectionDialog = false;
    if (tree) {
      this.settingValueConfig = {};
      this.updateDetails()
      this.updateChoosenTree()
      await this.loadDatasource()
      let useList = cloneDeep(this.usingWidgetDetails.filter(x => x.columntype !== ColumnType.Footer && x.columntype !== ColumnType.summaryColumn && !x.delflg))
      useList.forEach((u: any) => {
        u.value = u.columnname 
        u.columnType = ColumnType.InUse
        this.deleteColumn(u, u.columnType);
      });
      this.sortParam = null;
      await this.formatData();
      this.showValidateErrDS = false;
    }
  }

  openConfirmDialog(dialogType: DialogType) {
    this.modalService.open(ConfirmDialogComponent, {
      data: {
        dialogType: dialogType,
      }
    }).onClose.subscribe(() => {
      this.goBack()
    })
  }

  onClickChart(event: GraphType | null) {
    this.widget.charttype = event as GraphType
    const a = new Date()
    this.runFormating()
    this.runPivoting()
    this.generateTable()
    const b = new Date()
    // console.log('calculating:', Number(b) - Number(a))
  }

  onFilterDataClick(event: any) {
    this.modalCondition.style = { width: '421px', 'min-width': '421px' };
    this.dataType = this.getDataType(event.datatype ? event.datatype : '');
    let listColumnsF = this.widgetdetails.filter(x => x.columnname === event.value); 
    if(listColumnsF.length > 1) {
      this.columnF = listColumnsF.filter(c => c.datatype === event.datatype).pop() as WidgetDetail;
    } else {
      this.columnF = listColumnsF.pop() as WidgetDetail;
    }
    this.filterF = {
      filtertype: this.columnF.filtertype as string,
      filtervalue: this.columnF.filtervalue,
    } 

    this.displayConditionModal = true;
  }

  onFilterPivotTableClick(event: any) {
    this.modalCondition.style = { width: '421px', 'min-width': '421px' };
    event.datatype = event.datatype == DATATYPE.Date || event.datatype == DATATYPE.String ? DATATYPE.Number : event.datatype;

    this.dataType = this.getDataType(event.datatype);
    this.columnF = this.widgetdetails.filter(x => x.columnname === event.value).pop() as WidgetDetail
    this.pivotFilterF = {
      filtertype: this.columnF.pivotfiltertype as string,
      filtervalue: this.columnF.pivotfiltervalue,
    }
    this.isFilterPivotTable = true;
  }

  getDataType(type: string) {
    switch (type.toUpperCase()) {
      case "VARCHAR":
        return "001";
      case "INT":
      case "FLOAT":
        return "002";
      case "DATETIME":
        return "003";
      // case "TOTALDAYSTYPE":
      //   return "totaldays";
      default:
        return "001";
    }
  }

  onSubmitDataCondition(data: { filtertype: string, filtervalue: any }) {
    if (data) {
      const f = this.filterParams.filter(item => item.columnname === this.columnF.columnname).pop()!
      if (f) {
        if (data.filtertype === NoFilter) {
          this.columnF.filtertype = undefined
          this.columnF.filtervalue = undefined
          this.filterParams = this.filterParams.filter(x => x.columnname !== this.columnF.columnname)
        } else {
          f.filtertype = data.filtertype
          f.filtervalue = data.filtervalue
        }
      } else {
        if (data.filtertype !== NoFilter) {
          this.columnF.filtertype = data.filtertype
          this.columnF.filtervalue = data.filtervalue
          this.filterParams.push(this.columnF);
        }
      }

      this.updatePivotConfig()
      this.runFiltering()
      this.runFormating()
      this.runPivoting()
      this.generateTable()

      // Update selected item in list-box
      this.selected.items = this.selected.items.map(x => x.value === this.columnF.columnname ? { ...x, ...this.columnF } : x)
    }
    this.displayConditionModal = false;
  }

  onSubmitFilterCondition(data: { filtertype: string, filtervalue: any }) {
    if (data) {
      const f = this.pivotFilterParams.filter(item => item.columnname === this.columnF.columnname).pop()!
      if (f) {
        if (data.filtertype === NoFilter) {
          this.columnF.pivotfiltertype = undefined
          this.columnF.pivotfiltervalue = undefined
          this.pivotFilterParams = this.pivotFilterParams.filter(x => x.columnname !== this.columnF.columnname)
        } else {
          this.columnF.pivotfiltertype = data.filtertype
          this.columnF.pivotfiltervalue = data.filtervalue
        }
      } else {
        if (data.filtertype !== NoFilter) {
          this.columnF.pivotfiltertype = data.filtertype
          this.columnF.pivotfiltervalue = data.filtervalue
          this.pivotFilterParams.push(this.columnF);
        }
        else {
          this.columnF.pivotfiltertype = undefined
          this.columnF.pivotfiltervalue = undefined
        }
      }
      this.widgetdetails.forEach( w => {
        if(w.columnname == this.columnF.columnname && !w.delflg) {
          w.pivotfiltertype = this.columnF.pivotfiltertype
          w.pivotfiltervalue = this.columnF.pivotfiltervalue
        }
      })
      this.value.items?.forEach((v: any) => {
        let findCol = this.usingWidgetDetails.find( w => w.columnname == v.columnname && !w.delflg)
        if(findCol?.pivotfiltertype) {
          v.pivotfiltertype = findCol.pivotfiltertype
          v.pivotfiltervalue = findCol.pivotfiltervalue
          v.onFilter = true
        }
        else { 
          v.onFilter = false
          v.pivotfiltertype = undefined
          v.pivotfiltervalue = undefined
        }
      })
      this.runFiltering()
      this.runFormating()
      this.runPivoting()
      this.generateTable()

      // Update selected item in list-box
      this.selected.items = this.selected.items.map(x => x.value === this.columnF.columnname ? { ...x, ...this.columnF } : x)
    }
    this.isFilterPivotTable = false;
  }

  onCloneData() {
    this.loadingService.isLoading.emit(true)
    delay(350).then(() => {
      this.widgetService.pass({ folderCd: this.widget.foldercd, mode: ScreenMode.ADD })
      this.mode = ScreenMode.ADD
      this.widget.widgetcd = undefined
      this.widget.id = undefined
      this.widget.widgetname += COMMON_TEXT.EXTEND_COPY
      this.widgetdetails.map(x => {
        x.id = undefined
        x.widgetcd = undefined
        x.widgetdetailcd = undefined
      })
      this.loadingService.isLoading.emit(false)
    })
  }

  async onDeleteBtnClick() {
    this.isDeleteWG = true;
    this.nameWidgetDelete = [this.widget.widgetname];
    await this.getAllDashboardNameThatUseSelectedWidget(this.widget);
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.DELETE_DATA_FAIL);
    this.isDisplayConfirmDeleteModal = true;
    this.delTxt = COMMON_TEXT.WIDGET;
  }
  
  async getAllDashboardNameThatUseSelectedWidget(arr: any) {
    this.loadingService.isLoading.emit(true);
    if(arr.widgetcd != "")
    {
      let result = await this.widgetService.getDashboardList(arr.widgetcd);

        if(result.data != null && result.data.length > 0)
        {
          this.dashboardNames = result.data[0].listDashboardName;
        }
        this.loadingService.isLoading.emit(false);
    }
  }

  onConfirmDeleteDlg(event: any) {
    if (event === true) {
      this.loadingService.isLoading.emit(true)
      this.widgetService.delete([this.widget.widgetcd as any])
        .then((res: any) => {
          this.loadingService.isLoading.emit(false)
          if(res.statuscode && res.statuscode == 200)
            this.openConfirmDialog(DialogType.delete)
        });
      this.widgetService.pass({ widgetCd: undefined, folderCd: this.targetFolder.folderCd || '', mode: ScreenMode.ADD });
      this.widgetService.setCreatingByTemplate({ widgetcdTemplate: null, dsStructCd: null, folderDSCd: null});
    }
    this.isDisplayConfirmDeleteModal = false;
  }

  onChangeOptionFooter({ optionSelected }: { optionSelected: option }) {
    const detail = this.widgetdetails.filter(x => x.columntype === ColumnType.Footer).pop()!
    this.footerItem.formattype = detail.formattype = optionSelected.value
    this.footerType = this.footerItem.formattype;
    
    this.runFormating()
    this.runPivoting()
    this.generateTable()
    this.widgetService.footertype$.emit(this.footerItem.formattype)
  }

  onChangeOptionsummaryColumn({ optionSelected }: { optionSelected: option }) {
    const detail = this.widgetdetails.filter(x => x.columntype === ColumnType.summaryColumn).pop()!
    this.summaryColumnItem.formattype = detail.formattype = optionSelected.value
    this.summaryColumnType = this.summaryColumnItem.formattype;
    this.runFormating()
    this.runPivoting()
    this.generateTable()
    this.widgetService.summaryColumntype$.emit(this.summaryColumnItem.formattype)
  }

  onFilterGroup(data : any) {
    this.dspFilterGrpModal = false;
    if(!data) return;
    let optionSelected = { name: 'グループ', value: FormatType.Group }
    let listColumnsF: any[] = this.widgetdetails.filter(x => x.columnname === data.columnname); 
    if(listColumnsF.length > 0) {
      let colSelected  = listColumnsF.pop() as WidgetDetail;
      this.isFilterGroup = true;
      if(!data.isCancel)
      {
       let filTerJson = JSON.stringify(data)
       if(colSelected) {
        let newCol = cloneDeep(colSelected);
        newCol.columnid = v4();
        newCol.datatype = colSelected.datatype;
        newCol.groupfilterval = filTerJson;
        newCol.formattype = colSelected.formattype;
        if(this.datasourceCDs.length > 0) newCol.datasourcecd = this.datasourceCDs[0];
        newCol.columntype = data.columntype == ColumnType.RowAndValue  ||  data.columntype == ColumnType.ColumnAndValue ? data.columntype - ColumnType.Value : data.columntype;
        let operator = colSelected.columnname?.includes(ColFilterGroup) ? colSelected.columnname?.replace(this.colFilterStr, '') : colSelected.columnname;
        newCol.operator = "{" + operator + "}";
        if(colSelected.datasourcecd == DSCUSTOM || colSelected.datasourcecd == DSTARGET) newCol.operator = colSelected.operator;
        newCol.columnname = !newCol.columnname?.includes(ColFilterGroup)? this.colFilterStr + newCol.columnname: newCol.columnname;
        let checkDuplicateCol = this.widgetdetails.filter(w => w.columnname == newCol.columnname && !w.delflg) || [];
        if(checkDuplicateCol.length == 0) {
          let newType: any = colSelected?.columntype  - newCol.columntype || ColumnType.InUse;
          colSelected.columntype = newType;
          colSelected.formattype = colSelected.formattype?.replace(FormatType.Group, '');
          this.widgetdetails.push(new WidgetDetail(newCol));
          this.pushColumnFilterToTable(this.sourceTable, newCol);
        }
        else {
          this.widgetdetails = this.widgetdetails.map( col => {
            if(col.columnname == newCol.columnname && !col.delflg) {
              col.groupfilterval = filTerJson;
              col.formattype = this.setFormatType(col.formattype, optionSelected);
            }
            return col;
          })
          colSelected.groupfilterval = filTerJson;
        }
        this.columnF = cloneDeep(colSelected);
        this.runFiltering();
        this.updateUsing();
        this.onChangeFormat({ itemSelected : [ this.columnF ], optionSelected : optionSelected});
       }
      }
      else {  
        colSelected.formattype = (colSelected as any).currentformattype;
        this.updatePivotConfig();
      }
    }
    this.widgetdetails = this.widgetdetails;
  }

  onChangeOption({ itemSelected, optionSelected }: { itemSelected: WidgetDetail[], optionSelected: option }) {
    if(itemSelected.length == 0) return;
    this.dspFilterGrpModal = false;
    if(optionSelected.value?.toString()?.includes(FormatType.Group)) {
      const a = itemSelected.pop()!
      const w: any = this.widgetdetails.filter(x => x.columnname === a.columnname && x.delflg == false).pop()!
      this.dspFilterGrpModal = true;
      // map datatype
      if(w) {
        if(w.columnname?.includes(ColFilterGroup)) {
          let mapColumn = this.widgetdetails?.find( col => col.columnname == w.columnname?.replace(this.colFilterStr, '') && !w.delflg);
          w.datatype = mapColumn?.datatype;
        }
        this.dataType = w?.datatype || '';
        if(w.formattype) {
          const types = w.formattype?.match(/.{2}/g)!
          const firstCharactersOfTypes = types.map((x: any) => x[0])
          const firstCharacterOfSelected = optionSelected.value[0]
          const index = firstCharactersOfTypes.indexOf(firstCharacterOfSelected)
          if (index !== -1) {
            types[index] = optionSelected.value
          } else {
            types.push(optionSelected.value)
          }
          w.formattype = types.join('')
        }
        else w.formattype = optionSelected.value
        w.currentformattype = a.formattype;
        this.itemSelected = w;
        let itemSlects : WidgetDetail[] = [];
        itemSlects.push(w);
      }
    }
    else {
      this.onChangeFormat({ itemSelected: itemSelected, optionSelected:  optionSelected});
    }
  }

  setColumnTypeForColumn(column : any, optionSelected: any = null) {
      if(!column) return;
       // column is value
       if((column.columntype == ColumnType.RowAndValue || column.columntype == ColumnType.ColumnAndValue) && column.columnname?.includes(ColFilterGroup)) {
        this.widgetdetails = this.widgetdetails.map( col => {
          if(col.columnname == column.columnname?.replace(this.colFilterStr, '')) {
            if(col.columntype) {
              col.columntype -= ColumnType.InUse;
              col.columntype += ColumnType.Value;
            }
            else col.columntype = ColumnType.Value;
            if(col.formattype) {
              if(column.columnname.includes(this.colFilterStr) && column.formattype)  col.formattype = this.setFormatType(column.formattype, optionSelected)?.replace(FormatType.Group, '');
              else col.formattype = this.setFormatType(col.formattype, optionSelected)?.replace(FormatType.Group, '');
            }
          }
          else if(col.columnname == column.columnname) {
            col.columntype -= ColumnType.Value;
            col.datatype = column.datatype;
            column = col;
          }
          return col;
        });
        this.updateUsing();
      }
      if(!optionSelected.value?.toString()?.includes(FormatType.Group) && column.columnname?.includes(ColFilterGroup)) {
        // if(optionSelected?.value?.toString().includes('A')) this.widgetdetails = this.widgetdetails?.filter(w => w.columnname != column.columnname);
        this.widgetdetails?.map( wdg => {
          if(optionSelected?.value?.toString().includes('A') && wdg.columnname == column.columnname) {
            wdg.delflg = true;
            wdg.formattype = FormatType.NoFormatA;
          }
          if(wdg.columnname == column.columnname?.replace(this.colFilterStr, '')) { 
            if(column.columntype)  {
              let type: number = wdg.columntype;
              if(type == 5 || type == 8 || wdg.columntype == ColumnType.InUse)  wdg.columntype -= ColumnType.InUse;
              if(optionSelected?.value?.toString().includes('A')) wdg.columntype += column.columntype;
              if(wdg.formattype) {
                wdg.formattype = wdg.formattype.replace(FormatType.Group, '');
                wdg.formattype = this.setFormatType(wdg.formattype, optionSelected);
              }
            }
          }
        });
        this.updateUsing();
      }
  }

  pushColumnFilterToTable(sourceTable: any[], column: WidgetDetail) {
    return sourceTable.map(row => {
      // get operator of row
      let operator = column.operator! as any
      for (const key in row) {
        // Get value for the current key
        let value = row[key]
        // Check if value is a string and not a decimal
        if (typeof row[key] === 'string' && !isDecimal(row[key]))   value = `\`${row[key]}\``
        // If value is null, replace it with an empty string
        if (value === null) value = "''" ;
        // Replace placeholders in the operator with actual values
        operator = operator.replaceAll(`{${key}}`, value)
      }
      // Calculate value based on operator
      let valueBefore = evaluateFormula(operator);
      // Check if valueBefore is a number and not NaN
      if(typeof valueBefore === 'number' && isNaN(valueBefore)) {
        row[column.columnname] = "";
      }
      else {
        // Apply rounding if specified
        if(!Utils.isNullOrEmpty(column.rounding)) {
          let rounding = column.rounding?.split(',') || [];
          let option =  parseInt(rounding[1]);
          if(option != 1) {
            let op = option > 1 ? option -1 : option;
            row[column.columnname] = getRoundNum(valueBefore, parseInt(rounding[0]) || 0, op || 0);
          }
          else {
            row[column.columnname] = roundNumDecimal(valueBefore, parseInt(rounding[0]));
          }
        }
        else {
          row[column.columnname] = evaluateFormula(operator)
        }
      }
    })
  }

  onChangeFormat({ itemSelected, optionSelected }: { itemSelected: WidgetDetail[], optionSelected: option }) {
    const a = itemSelected.pop()!
    const w = this.widgetdetails.filter(x => x.columnname === a?.columnname && x.delflg == false).pop()!
    if(w) {
      // column is value
      this.setColumnTypeForColumn(w, optionSelected);
      if (w.formattype) {
        if(optionSelected?.value != FormatType.Group && optionSelected?.value.toString()?.includes('A')) {
          w.formattype = w.formattype.replace(FormatType.Group, '');
          w.groupfilterval = undefined;
          if(this.columnF && this.columnF.columnname == w.columnname) this.columnF.groupfilterval = undefined;
        }
        if(!w.formattype?.toString()?.includes(FormatType.Group)) w.formattype = this.setFormatType(w.formattype, optionSelected);
      } else {
        w.formattype = optionSelected.value
      }
      if(!w.columnname?.includes(this.colFilterStr)) {
        w.formattype = w.formattype?.replace(FormatType.Group, '');
        w.formattype = this.setFormatType(w.formattype, optionSelected);
      }
      this.updatePivotConfig()
      this.runFormating()
      this.runPivoting()
    }
  }

  setFormatType(formatype: any, optionSelected: any) {
    if(!formatype) return optionSelected.value;
    const types = formatype?.match(/.{2}/g)!
    const firstCharactersOfTypes = types.map((x: any) => x[0])
    const firstCharacterOfSelected = optionSelected.value[0]
    const index = firstCharactersOfTypes.indexOf(firstCharacterOfSelected)
    if (index !== -1) {
      types[index] = optionSelected.value
    } else {
      types.push(optionSelected.value)
    }
    return types.join('');
  }

  onSubmitDefinedCol(data: any) {
    this.isDisplayDefineColumnDialog = false;
    if(!data) return;
    let {column, targetSettings, usedColumnsInTarget, usedColumnsInCustom }  = data;
    if(!this.settingValueConfig) {
      this.settingValueConfig = new SettingTargetConfig();
    }

    this.settingValueConfig.targetTable = targetSettings;
    this.customNode = null;
    this.customNode = this.createCustomNode();
    if (column && !Utils.isNullOrEmpty(column.columnname)) {
      if (this.selectedCustom) {
        this.customNode.nodes?.map(x => {
          if (x.id === column.columnname) {
            x.data = [column]
            x.label = column.displayname
          }
          const w = this.widgetdetails.filter(x => x.columnname === column.columnname).pop() as WidgetDetail
          w.columnname = column.columnname
          w.displayname = column.displayname
          w.operator = column.operator
          w.rounding = column.rounding
          w.datatype = column.datatype
        })
      } else {
        column.sortno = this.customNode.nodes?.length || 1
        column.widgetcd = this.widget.widgetcd
        this.customNode.nodes?.push({
          id: column.columnname,
          label: column.displayname,
          draggable: true,
          isLastNode: true,
          data: [column]
        })
        this.widgetdetails.push(new WidgetDetail(column))
      }
    }
    //Detete targetColumns
    const targetColumns = this.widgetdetails.filter(x => x.datasourcecd === DSTARGET && !x.delflg);
    const deleteTargetColumns = targetColumns.filter((item: WidgetDetail) =>  targetSettings.filter((target: WidgetSettingRecord) =>
                                                                              target.targetColumnName == item.columnname).length ==0);
    if(deleteTargetColumns) {
      deleteTargetColumns.map((item) => item.delflg = true);
    }
    this.customNode!.nodes = this.customNode?.nodes?.filter(x => deleteTargetColumns.filter(target =>
                                                              x.id == target.columnname).length >0)
    //Add target column to widget
    if(targetSettings && targetSettings.length > 0) {
      //add target column to custom nodes
      targetSettings.forEach((setting: WidgetSettingRecord) => {
        if(this.choosenDSTree.nodes.length == 2) {
          let isExist = this.choosenDSTree.nodes[1]?.nodes?.filter((node: TreeNode) => node.id ===  setting.targetColumn.columnname).pop();
          if(isExist) {
            isExist.data = [setting.targetColumn]
          }
          else {
            this.customNode!.nodes?.push({
              id: setting.targetColumn.columnname,
              label: setting.targetColumn.displayname,
              draggable: true,
              isLastNode: true,
              data: [setting.targetColumn]
            })
          }
        }
        else {
          this.customNode!.nodes?.push({
            id: setting.targetColumn.columnname,
            label: setting.targetColumn.displayname,
            draggable: true,
            isLastNode: true,
            data: [setting.targetColumn]
        })
      }
        this.handleEachOfSetting(setting);
      })
    }

    //Add used columns in formula in target
    if(usedColumnsInCustom && usedColumnsInCustom.length > 0 ) {
      if(usedColumnsInCustom.filter((col: WidgetDetail) => col.datasourcecd === DSTARGET).length >0 ){
        usedColumnsInCustom.map((col: WidgetDetail) => col.columntype = ColumnType.Hidden_Value)
      }
    }
   // let targetColumnsInCustomColumns = 
    let hiddenColumns = [...usedColumnsInTarget, ...usedColumnsInCustom];
    if(hiddenColumns && hiddenColumns.length) {
      
      hiddenColumns.forEach((column: WidgetDetail) => this.hanldeEachUsedColumnsInTarget(column))
    }
  
    if(this.customNode) this.customNode.expanded = true
    this.widgetdetails = this.widgetdetails.map(item => {
      if(item.formattype?.toString()?.includes(FormatType.Group)) {
        if(item.datatype == DATATYPE.Float || item.datatype == DATATYPE.Number) item.datatype = DATATYPE.String;
      }
      return item;
    });
    this.updateUsing()
    this.updateChoosenTree(true)
    pushColumnToTable(this.sourceTable, column)

    this.selectedCustom && this.generateTable()
    
    this.selectedCustom = null;

    if(targetSettings && targetSettings.length > 0) {
      this.widget.charttype = GraphType.TABLE_CHART;
    }
   
    this.widget.charttype = GraphType.TABLE_CHART;
    this.runFiltering();
    this.runFormating();
    this.runPivoting();
  }


  handleEachOfSetting(setting: WidgetSettingRecord) {
    if(setting) {
      // Start add target column in values box
      //Set pivotorder for targetcolumn with the current max value of order in values box.
      const values = this.value.items.map((obj:any) => obj['pivotorder']);
      let maxPivotOrderValues = values.length ? Math.max(...values) : 0;
      let exsitTargetCol = this.widgetdetails.filter((item) => item.columnname === setting.targetColumn.columnname && !item.delflg).pop();
      if(!exsitTargetCol) {
        setting.targetColumn.pivotorder = maxPivotOrderValues + 2;
        this.widgetdetails.push(new WidgetDetail(setting.targetColumn))
      }
      else {
        if(setting.targetColumn.columntype) {
          let predictColumnType = exsitTargetCol.columntype + setting.targetColumn.columntype ;
          if(predictColumnType == ColumnType.Value )  exsitTargetCol.pivotorder = maxPivotOrderValues + 2;
          if(InValue[predictColumnType]) exsitTargetCol.columntype += setting.targetColumn.columntype || 0;
        }
      } 
      // Start add compared column into values box
      let exsitCol = this.widgetdetails.filter((item) => item.columnname === setting.column.data?.columnname).pop();
      if(exsitCol) {
        if(exsitCol.columntype == ColumnType.NoUse) {
          exsitCol.pivotorder = maxPivotOrderValues + 1;
          exsitCol.sortno = 0;
          exsitCol.columntype += setting.column.data?.columntype || 0;
        }

        else {
          if(setting.column?.data?.columntype) {
            let predictColumnType = exsitCol.columntype + setting.column.data?.columntype ;
            if(predictColumnType == ColumnType.Value){
              exsitCol.sortno = 0;
              exsitCol.pivotorder = maxPivotOrderValues + 1;
            }
            if(InValue[predictColumnType]) exsitCol.columntype += setting.column.data?.columntype || 0;
          }
        }
      }
      else {
        // Set pivotorder for compared column with the current max value of order in values box.
        if(setting.column.data) setting.column.data.pivotorder = maxPivotOrderValues + 1;
        this.widgetdetails.push(new WidgetDetail(setting.column.data))
      }
      // Start add selected row into rows box
      let exsitRow = this.widgetdetails.filter((item) => item.columnname === setting.row.code).pop();
      //Set pivotorder for targetcolumn with the current max value of order in values box.
      const rows = this.rows.items.map((obj:any) => obj['pivotorder']);
      let maxPivotOrderRows = rows.length ? Math.max(...rows) : 0;
      if(exsitRow) {
        let formattype = setting?.row?.data?.formattype;
        exsitRow.formattype = formattype;
        if(exsitRow.columntype == ColumnType.NoUse) {
          exsitRow.pivotorder = maxPivotOrderRows + 1;
          exsitRow.sortno = 0;
          exsitRow.columntype += setting.row.data?.columntype || 0;
        }
        else {
          if(setting.row.data?.columntype) {
            let predictRowType = exsitRow.columntype + setting.row.data?.columntype;
            if(predictRowType == ColumnType.Row) {
              setting.row.data.pivotorder = maxPivotOrderRows + 1;
              setting.row.data.sortno = 0;
            }
            if(InRow[predictRowType]) exsitRow.columntype += setting.row.data?.columntype || 0;
          }
          
        }
      }
      else {
         // Set pivotorder for selected row with the current max value of order in rows box.
        if(setting.row.data?.pivotorder) setting.row.data.pivotorder = maxPivotOrderRows + 1;
        this.widgetdetails.push(new WidgetDetail(setting.row.data))
      }
    }
  }

  hanldeEachUsedColumnsInTarget(column: WidgetDetail) {
    if(column) {
      let exsitTargetCol = this.widgetdetails.filter((item) => item.columnname === column.columnname && !item.delflg).pop();
      if(exsitTargetCol) {
        let predictColumnType = exsitTargetCol.columntype + column.columntype ;
        if(InHiddenValue[predictColumnType]) exsitTargetCol.columntype += column.columntype || 0;
      }
    }
  }

  onDoubleClick(node: TreeNode) {
    let w = node.data ? node.data[0] : {}
    if (node.isLastNode && ( w.datasourcecd === DSCUSTOM || w.datasourcecd === DSTARGET)) {
      this.selectedCustom = this.widgetdetails.filter(x => x.columnname === node.id).pop()!
      this.customNameColumn =  this.selectedCustom.columnname;

      this.openDefinedColumnDlg(false)
    }
  }

  changeName(name: string) {
    this.widget.widgetname = name.trim()
  }

  canSave(): boolean {
    let isValidModeAndWidgetName = this.mode !== ScreenMode.PREVIEW && !!this.widget.widgetname?.trim();
    let hasDatasourceCDs = this.datasourceCDs.length > 0;
    if(this.isWidgetTemplate) {
      return isValidModeAndWidgetName && Object.keys(this.selectedRangeDate).length !== 0 && hasDatasourceCDs;
    }
    else return isValidModeAndWidgetName && hasDatasourceCDs
  }

  checkHaveDS(): boolean {
    if (this.datasourceCDs.length > 0){
      this.showValidateErrDS = false; 
      return true 
    }else {
      this.showValidateErrDS = true;
      return false 
    }
  }
  
  loadingSomething(key: string, state = true) {
    if (state) {
      this.loadingService.isLoading.emit(true)
      this.loadingBag['_count'] ??= 0
      this.loadingBag['_count']++
    } else {
      this.loadingBag['_count']--
    }

    this.loadingBag[key] = state

    if (!this.loadingBag['_count']) this.loadingService.isLoading.emit(false)
  }

  checkValidateWidgetName() {
    if (Utils.isNullOrEmpty(this.widget?.widgetname)) {
      this.nameParams.validate = true;
      this.nameParams.isValidate = true;
    }
    else {
      this.nameParams.validate = false;
      this.nameParams.isValidate = false;
    }
    this.nameParams = cloneDeep(this.nameParams);
  }

  checkValidateName() {
    this.nameParams.validate = false;
    this.nameParams = cloneDeep(this.nameParams);
  }

  onConfirmSaveData(event: any, isClickMenu: boolean = false): boolean {
    if (event == this.saveType.SAVE) {
      this.checkValidateWidgetName();
      let checkHaveDS = this.checkHaveDS();
      if (!this.canSave() || !checkHaveDS ) return false;
      if (!this.widget.charttype) {
        this.widget.charttype = GraphType.NO_CHART;
      }
      this.widget.sortcoltype = JSON.stringify(this.sortParam || '');
      const request: WidgetRequest = {
        widget: this.widget,
        widgetdetails: this.widgetdetails.filter(x => !x.delflg || x.id)
      };
      this.loadingService.isLoading.emit(true);
      this.widgetService.postWidget(request).then(res => {
        if (this.datasourceCDs.length > 0){
          this.createWidgetResultFromWidgetCd(res, 2, isClickMenu);
        }
        else {
          this.backWidgetCreation(isClickMenu);
        }
      });
    }
    else if (event == this.saveType.NO) {
      this.widgetService.pass({ widgetCd: undefined, folderCd: this.targetFolder.folderCd || '', mode: ScreenMode.ADD });
      this.widgetService.setCreatingByTemplate({ widgetcdTemplate: null, dsStructCd: null, folderDSCd: null});
      if (!isClickMenu) {
        if (this.isWidgetTemplate) {
          this.navigateTo(ROUTE_PATH.WIDGET_TEMPLATE_LIST);
        } else {
          this.navigateTo(ROUTE_PATH.WIDGET_LIST);
        }
      }
    }
    else {
      this.widgetService.pass({ widgetCd: undefined, folderCd: this.targetFolder.folderCd || '', mode: ScreenMode.ADD });
      this.widgetService.setCreatingByTemplate({ widgetcdTemplate: null, dsStructCd: null, folderDSCd: null});
      this.checkValidateName();
    }

    return true;
  }


  mapDssCdForCol() {
    this.widgetdetails.forEach(w => {
      if(w.columntype == ColumnType.Footer || w.columntype == ColumnType.summaryColumn) {
        w.datasourcecd = w.columnname
      }
    })
  }

  isFilterDate() {
    return this.usingWidgetDetails?.filter(s => s.datatype == DATATYPE.Date && FilterDateCurrent.filter(ft => ft.value == s.filtertype)?.length > 0)?.length > 0 ? true : false;
  }
  //#endregion

  //#region Chốt

  save() {
    if (!this.canSave()) return;
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SAVE_DATA_FAIL);
    this.errorHandleService.backURLSub.next("");
    if (!this.widget.charttype) {
      this.widget.charttype = GraphType.NO_CHART
    }
    if(this.isCreatingWidgetByTemplate) {
      this.widgetdetails = this.usingWidgetDetails;
    }
    // let isFilterDate: boolean = this.usingWidgetDetails?.filter(s => s.datatype == DATATYPE.Date && FilterDateCurrent.filter(ft => ft.value == s.filtertype)?.length > 0)?.length > 0 ? true : false;
    if(this.isFilterDate()) this.getDateRangeByPeriod(this.groupedPeriod);
    let startDate = this.selectedRangeDate.startDate;
    let endDate = this.selectedRangeDate.endDate ? this.selectedRangeDate.endDate: '未定';
    this.widget.foldercd = this.targetFolder.folderCd;
    this.mapDssCdForCol()
    this.graphConfig = this.graphConfig || [];
    this.graphConfig = this.graphConfig.length > 0
        ? this.graphConfig.map((config: any) => ({ ...config, isStackedChartSetting: this.isStackedChartSetting }))
        : [{ Checked: null, GraphType: null, IsSecondAxis: null, RowIndex: null, isStackedChartSetting: this.isStackedChartSetting }];
    let widgetconfig = {};
    if (this.widget.charttype === GraphType.STACKED_BAR){
      widgetconfig = {
        id: this.settingValueConfig ? this.settingValueConfig.id : "",
        targetConfig: this.settingValueConfig ? JSON.stringify(this.settingValueConfig.targetTable) : "",
        graphConfig: this.graphConfig ? JSON.stringify(this.graphConfig) : ""
      }
    }else {
      widgetconfig = {
        id: this.settingValueConfig ? this.settingValueConfig.id : "",
        targetConfig: this.settingValueConfig ? JSON.stringify(this.settingValueConfig.targetTable) : "",
        graphConfig: this.graphConfig && this.isSavedGraphConfig ? JSON.stringify(this.graphConfig) : ""
      }
    }
    this.widget.sortcoltype = JSON.stringify(this.sortParam || '');
    const request: WidgetRequest =  !this.isWidgetTemplate ? {
      widget: this.widget,
      widgetdetails: this.widgetdetails.filter(x => !x.delflg || x.id),
      widgetconfig: widgetconfig ? { ...widgetconfig }: null
    } : {
      widget: this.widget,
      publicdate: `${startDate}~${endDate}`,
      widgetdetails: this.widgetdetails.filter(x => !x.delflg || x.id),
      widgetconfig:  { ...widgetconfig }
    }

    this.loadingService.isLoading.emit(true)
    this.widgetService.postWidget(request)
      .then(res => {
        if (this.datasourceCDs.length > 0){
          this.createWidgetResultFromWidgetCd(res, 1);
        }else {
          this.saveWidgetCreation(res);
        }
      })
      .catch(() => this.loadingService.isLoading.emit(false))
      .finally();
  }

  createWidgetResultFromWidgetCd(res: any, type: number, isClickMenu: boolean = false) {
    this.widgetResultService.createWidgetResultFromWidgetCdAndDatasourceCd(res.data, this.datasourceCDs[0]).then(resWR => {
      this.loadingService.isLoading.emit(false);
      if (resWR.statuscode == 200) {
        if (type == 1) {
          this.saveWidgetCreation(res);
        } else if (type == 2) {
          this.backWidgetCreation(isClickMenu);
        }
      }
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
    })
  }
  
  saveWidgetCreation(res: any){
    if(this.isCreatingWidgetByTemplate && this.isUsingDSTemplate){
      this.isCreateWidgetByDSTemplate = true;
    }
    this.loadingService.isLoading.emit(false);
    this.widgetService.pass({ widgetCd: res.data, folderCd: this.widget.foldercd, mode: this.mode , type: this.widget?.charttype, 
      description: this.widget?.widgetdesc, sortcoltype: this.widget?.sortcoltype, name: this.widget?.widgetname });
    this.widgetService.setCreatingByTemplate({ widgetcdTemplate: null, dsStructCd: null, folderDSCd: null});
    this.openConfirmDialog(DialogType.save)
  }
  
  backWidgetCreation(isClickMenu: boolean){
    this.widgetService.pass({
      widgetCd: undefined,
      folderCd: this.targetFolder.folderCd || '',
      mode: ScreenMode.ADD,
    });
    this.widgetService.setCreatingByTemplate({
      widgetcdTemplate: null,
      dsStructCd: null,
      folderDSCd: null,
    });
    if (!isClickMenu) {
      if (this.isWidgetTemplate) {
        this.navigateTo(ROUTE_PATH.WIDGET_TEMPLATE_LIST);
      } else {
        this.navigateTo(ROUTE_PATH.WIDGET_LIST);
      }
    }
  }

  cancel() {
    this.widgetService.pass({ widgetCd: undefined, folderCd: this.targetFolder.folderCd || '', mode: ScreenMode.ADD });
    this.widgetService.setCreatingByTemplate({ widgetcdTemplate: null, dsStructCd: null, folderDSCd: null});
    this.goBack()
  }
  //#endregion

  goBack() {
    let dashboardItem = this.dashboardService.getDashboardItem();
    if (!Utils.isNullOrEmpty(dashboardItem?.dashboard)) {
      const mode = dashboardItem?.mode == ScreenMode.ADD ? ScreenMode.ADD : ScreenMode.EDIT;
      let url;
      if (this.isWidgetTemplate) {
        url = mode == ScreenMode.ADD ? ROUTE_PATH.DASHBOARD_TEMPLATE_CREATE : ROUTE_PATH.DASHBOARD_TEMPLATE_DETAIL;
      } else {
        url = mode == ScreenMode.ADD ? ROUTE_PATH.DASHBOARD_CREATE : ROUTE_PATH.DASHBOARD_DETAIL;
      }
      this.navigateTo("URL", [LocalStorageHelper.getUrl(url), dashboardItem.dashboard?.dashboardCd, mode]);
    } else {
      if (this.isWidgetTemplate) {
        this.navigateTo(ROUTE_PATH.WIDGET_TEMPLATE_LIST)
      } else {
        this.navigateTo(ROUTE_PATH.WIDGET_LIST)
      }
    }
  }

  backPage() {
    const refBackPage = this.modalService.open(ConfirmUnsavedDataDialogComponent, {
      header: COMMON_TEXT.CONFIRM_NOT_SAVE_DATA,
      width: '35%'
    });

    refBackPage.onClose.subscribe((x) => {
      this.onConfirmSaveData(x);
    });
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.useCanDeactivate && !this.backActionFromBrowser) {
      if(this.isByPassConfirmDialog) {
        this.errorHandleService.isByPassConfirmDialogSub.next(false);
        return true;
      }
      if (this.mode != ScreenMode.PREVIEW) {
        const ref = this.modalService.open(ConfirmUnsavedDataDialogComponent, {
          header: COMMON_TEXT.CONFIRM_NOT_SAVE_DATA,
          width: '35%'
        });

        return ref.onClose.pipe(map(x => {
          if (!x || x == SaveType.CANCEL) return false;

          return this.onConfirmSaveData(x, true);
        }));
      }
      else {
        return true;
      }
    } else {
      this.backActionFromBrowser ? this.widgetService.pass({ widgetCd: undefined, folderCd: this.targetFolder.folderCd || '', mode: ScreenMode.ADD }) : true;
      this.useCanDeactivate = true;
      this.backActionFromBrowser = false;
      return true;
    }

  }

  navigateTo(screen: string, commands: any[] = []) {
    this.useCanDeactivate = false;
    switch (screen) {
      case ROUTE_PATH.WIDGET_LIST:
      case ROUTE_PATH.WIDGET_TEMPLATE_LIST:
        this.router.navigate([LocalStorageHelper.getUrl(screen)]);
        break;
      case "URL":
        this.router.navigate(commands);
        break;
      default:
        break;
    }
  }

  setModeInputName() {
    if (this.mode == ScreenMode.PREVIEW) {
      this.nameParams.pencil = false;
      this.nameParams.readonly = true;
      this.nameParams = cloneDeep(this.nameParams);
    }
    else {
      this.nameParams.pencil = true;
      this.nameParams.readonly = false;
      this.nameParams = cloneDeep(this.nameParams);
    }
  }

  showGraphsetting(){
    this.showGraphSetting.next();
  }
  chooseWidgetTemplate() {
    this.useCanDeactivate = false;
    this.isCreatingWidgetByTemplate = true;
    this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.DASHBOARD_WIDGET_TEMPLATE), 4]);
  }

  openRangeDateModal() {
    this.isDisplayRangeDateModal = true;
  }

  closeRangeDateModal() {
    this.isDisplayRangeDateModal = false;
  }

  setRangeDate(event: any){
    this.selectedRangeDate = event;
    this.isDisplayRangeDateModal = false;
    if (Object.keys(event)) {
      if (event.startDate) {
        this.startDate = new Date(event.startDate.replace(/\//g, '-').replace(' ', 'T'))
      } else {
        this.startDate = null;
      }
      if (event.endDate) {
        this.endDate = new Date(event.endDate.replace(/\//g, '-').replace(' ', 'T'))
        this.isDisplayEdate = true;
      } else {
        this.endDate = null;
        this.isDisplayEdate = false;
      }
    } else {
      this.startDate = new Date();
      this.endDate = null;
      this.isDisplayEdate = false;
    }
  }

  formatRangeDate(rangeDate: any) {
    if (Object.keys(rangeDate).length && rangeDate.startDate) {
      if (!rangeDate.endDate) return `${moment(rangeDate.startDate).format(DateFormat.FULL_SHORT_DATE)} ~ 未定`;
      if (moment(rangeDate.startDate).format("L") == moment(rangeDate.endDate).format("L"))
        return moment(rangeDate.startDate).format(DateFormat.FULL_SHORT_DATE);
      return `${moment(rangeDate.startDate).format(DateFormat.FULL_SHORT_DATE)} ~ ${moment(rangeDate.endDate).format(DateFormat.FULL_SHORT_DATE)}`;
    }
    return "";
  }
  
  showSettingTarget() {
    this.isShowSettingTarget = true;
  }

  saveChartConfig(config: any) {
    if(config.isSet) {
      this.isSavedGraphConfig = true;
    }
    this.graphConfig = config.data;
  }

  canSetting(): boolean {
    if(!this.widget.charttype) return false;
    if(this.cols.items.length > 0) return false;
    let chartData: any = cloneDeep(this.tableData);
    if(chartData && chartData.config)
    {
      if(chartData.config.footers.length > 0 || chartData.config.summaryColumns.length > 0) {
        if(chartData.config.footers[0].formattype != undefined)
          return false;
        if(chartData.config.summaryColumns[0].formattype != undefined)
          return false; 
      }
    }
    return true;
  }

  onRenderChartComplete(){
    this.loadingService.isLoading.emit(false);

  }
  onStartRenderChart(){
   this.loadingService.isLoading.emit(true);
  }
}